import { FormEvent } from 'react'
import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Purchase from '../../../models/purchase'
import { formPurchase, listPurchase } from '../storage'
import FormPurchase from './FormPurchase'
import val from '../../../lib/val'
import ModalAreYouSure from './ModalAreYouSure'
import modalConfirm, { close } from '../../../lib/modalConfirm'

export default function usePurchase(purchase?: Purchase) {
    const { observer, submit } = useSubmit(formPurchase, {
        path: purchase ? `/api/purchases/${purchase.id}` : '/api/purchases',
        method: purchase ? 'put' : 'post',
        done: () => listPurchase.load()
    })

    observer<any>(f => ({
        provider_id: f.provider_id,
        voucher_type: f.voucher_type,
        voucher_number: f.voucher_number,
        warehouse_id: f.warehouse_id,
        total: f.total,
        purchase_supplies: f.purchase_supplies.map((_f: any) => {
            return {
                purchase_id: Number(_f.purchase_id),
                supply_id: Number(_f.supply_id),
                supply_presentation_id: Number(_f.supply_presentation_id),
                quantity: Number(_f.quantity),
                unit_cost: Number(_f.unit_cost),
                total: Number(_f.total)
            }
        })
    }))

    function submitOpenHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()
        const form = formPurchase.store()
        const v = val(form)
        v.provider_id.isEmpty('Es requerido el proveedor')
        v.warehouse_id.isEmpty('Es requerido el almacén')
        v.purchase_supplies.condition(value => {
            return value.length === 0
                ? 'Es requerido añadir al menos un producto'
                : ''
        })
        v.provider_id.isEmpty('Es requerido el proveedor')
        v.warehouse_id.isEmpty('Es requerido el almacén')

        const getDetail = (purchaseSupply: any) => {
            if (purchaseSupply.supply_presentation) {
                return {
                    name:
                        purchaseSupply.supply.name +
                        ' - ' +
                        purchaseSupply.supply_presentation.name
                }
            } else {
                return {
                    name: purchaseSupply.supply.name
                }
            }
        }
        v.purchase_supplies.condition(value => {
            return value.some((item: any) => item.quantity === 0)
                ? `La cantidad del producto ${
                      getDetail(value.find((item: any) => item.quantity === 0))
                          .name
                  } debe ser mayor a 0`
                : ''
        })

        const submitConfirmHandler = () => {
            close()
            submit()
        }

        const result = v.runtest()
        if (result) {
            formPurchase.setHelpers(result)
            return
        } else {
            modalConfirm(ModalAreYouSure, {
                cleaned: true,
                size: 'md',
                onSubmit: submitConfirmHandler
            })
        }
    }

    function openModal() {
        if (purchase) {
            formPurchase.store({
                id: purchase.id.toString() || '',
                provider_id: purchase.provider_id?.toString() || '',
                voucher_type: purchase.voucher_type?.toString() || '',
                voucher_number: purchase.voucher_number?.toString() || '',
                warehouse_id: purchase.warehouse_id?.toString() || '',
                total: purchase.total?.toString() || '',
                purchase_supplies:
                    purchase.purchase_supplies?.map((purchase_supply: any) => {
                        return {
                            id: purchase_supply.id || null,
                            purchase_id: purchase_supply.purchase_id,
                            supply: purchase_supply.supply,
                            supply_presentation:
                                purchase_supply.supply_presentation,
                            supply_id: purchase_supply.supply_id,
                            supply_presentation_id:
                                purchase_supply.supply_presentation_id || null,
                            quantity: purchase_supply.quantity,
                            unit_cost: purchase_supply.unit_cost,
                            total: purchase_supply.total
                        }
                    }) || []
            })
            formPurchase.init(formPurchase.store)
        } else {
            formPurchase.init()
        }
        modal(FormPurchase, {
            title: purchase ? 'Editar Compra' : 'Nueva Compra',
            size: '5xl',
            closeOnOverlayClick: false,
            onSubmit: submitOpenHandler
        })
    }

    return openModal
}

// interface FormSubmit {
//     name: string
// }
