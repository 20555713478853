import { listSupply } from '../storage'
import { Box, SimpleGrid, Checkbox } from '@chakra-ui/react'
import React, { useContext } from 'react'
import ContextSupplySearch from './ContextSupplySearch'

export default function SupplyRow({
    warehouse,
    purchaseSupplies
}: {
    warehouse: any
    purchaseSupplies: any
}) {
    if (warehouse) {
        const newQuery = purchaseSupplies
            .map((item: any) => {
                const sId = item.supply_id
                const spId = item.supply_presentation_id
                return `${sId}|${spId ? spId : 0}`
            })
            .join(',')
        const oldAdded = listSupply.getQuery('added') ?? ''
        const newAdded = newQuery
        if (
            listSupply.getQuery('warehouse_id') !== warehouse.id ||
            oldAdded !== newAdded
        ) {
            listSupply.setQuery('warehouse_id', warehouse.id)
            listSupply.setQuery('added', newQuery)
            listSupply.load()
        }
    }
    const { supplyChecked, setSupplyChecked } =
        useContext<any>(ContextSupplySearch)

    const data = listSupply.useData()

    const isSupplyChecked = (id: any, type: string) => {
        return supplyChecked.some(
            (item: any) => item.id === id && item.type === type
        )
    }

    const handleOnChange = (checked: boolean, model: any, type: string) => {
        if (checked) {
            const newChecked = {
                id: model.id,
                type: type,
                model: model
            }
            setSupplyChecked((prevState: any) => [...prevState, newChecked])
        } else {
            setSupplyChecked((prevState: any) =>
                prevState.filter((item: any) => {
                    return !(
                        item.type == type && String(item.id) == String(model.id)
                    )
                })
            )
        }
    }

    const getDetail = (supply: any) => {
        if (
            !supply.supply_presentations ||
            supply.supply_presentations.length == 0
        ) {
            return {
                key: 'addSupplyId' + supply.id,
                id: supply.id,
                item: supply,
                name: supply.name,
                type_measure_name: supply.type_measure?.name ?? '-',
                type: 'supply',
                stock: supply.warehouse_supply?.stock ?? '-'
            }
        }
        return {
            key: 'addSupplyPresentationId' + supply.supply_presentations[0].id,
            id: supply.supply_presentations[0].id,
            item: { ...supply.supply_presentations[0], supply: supply },
            name: supply.name + ' - ' + supply.supply_presentations[0].name,
            type_measure_name:
                supply.supply_presentations[0].type_measure?.name ?? '-',
            type: 'supply_presentation',
            stock: supply.supply_presentations[0].warehouse_supply?.stock ?? '-'
        }
    }

    return (
        <Box style={{ overflowY: 'auto', maxHeight: '450px' }}>
            {data.map(supply => (
                <SimpleGrid
                    key={getDetail(supply).key}
                    gridTemplateColumns="[col1-start] 60px [col2-start] repeat(4, 1fr)"
                    columns={5}
                    textAlign="center"
                    className="row tr"
                >
                    <Box width="60px" color="gray.600" py="4">
                        <Checkbox
                            px="4"
                            py="2"
                            borderColor={'gray.500'}
                            colorScheme="green"
                            bg={'transparent'}
                            onChange={e =>
                                handleOnChange(
                                    e.target.checked,
                                    getDetail(supply).item,
                                    getDetail(supply).type
                                )
                            }
                            isChecked={isSupplyChecked(
                                getDetail(supply).id,
                                getDetail(supply).type
                            )}
                        ></Checkbox>
                    </Box>
                    <Box color="gray.600" py="4">
                        {getDetail(supply).name}
                    </Box>
                    <Box color="gray.600" py="4">
                        {getDetail(supply).type_measure_name}
                    </Box>
                    <Box color="gray.600" py="4">
                        {warehouse?.name ?? '-'}
                    </Box>
                    <Box color="gray.600" py="4">
                        {getDetail(supply).stock}
                    </Box>
                </SimpleGrid>
            ))}
        </Box>
    )
}
