import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listProvider } from '../storage'

export default function FiltersProvider() {
    const { clear, ...props } = listProvider.useSearch()

    return (
        <Flex mr="4">
            <Clean value={props.value} onClick={clear}>
                <Input
                    w={{ base: 'full', md: '550px' }}
                    placeholder="🏸 Buscar por nombre o número de documento"
                    {...props}
                />
            </Clean>
        </Flex>
    )
}
