import BoxTotal from '../Components/BoxTotal'
import { storagePage } from '../storage'
import { CashboxUserData } from '../types'

export default function TotalSale() {
    const cashboxUser = storagePage.cashboxUser as CashboxUserData
    const value = cashboxUser.payment_amounts.reduce(
        (acc, curr) => acc + curr.amount,
        0
    )

    return (
        <BoxTotal
            w={{ base: '100%', xl: '70%' }}
            title="Total de venta"
            value={value}
            paymentAmounts={cashboxUser.payment_amounts}
            wTarget={null}
        />
    )
}
