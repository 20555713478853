import { useHistory, useParams } from 'react-router-dom'
import { useState } from 'react'
import { useRender } from 'redity'
import ContentModalLoading from '../ModalContents/ContentModalLoading'
import { post } from '../../../lib/http'
import modal, { close } from '../../../lib/modal'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import {
    BodyGenerateSunatVoucher,
    ResponseGenerateSunatVoucher,
    ResponsePostOrder
} from '../types'
import useSelectedItems from './useSelectedItems'
import { useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import getBodyPayment from '../utilities/getBodyPayment'
import clearStates from '../utilities/clearStates'
import useDialog from '../../../hooks/useDialog'
import Client from '../../../models/client'
import { Exception, Result } from '../../../lib/http/type'
import getPrinterConfig from '../../../utilities/getPrinterConfig'
import { DataModalResult } from './types'
import useSession from '../../../hooks/useSession'

export default function useDetails() {
    const { order_id } = useParams<{ order_id?: string }>()
    const { account_id } = useParams<{ account_id?: string }>()
    useRender(Keys.DETAILS)

    const { session } = useSession()

    const dialogAlias = useDialog({
        title: 'Operación Inválida',
        description: (
            <>
                Se requiere indicar el <b>Alias</b> del cliente, es requerido en
                pedidos <b>Para llevar</b> y <b>Delivery</b>
            </>
        )
    })
    const dialogInvalidOperationRUC = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                Es necesario que el cliente tenga <b>documento RUC</b> para la
                emisión de factura
            </>
        )
    })
    const dialogRequiredClientNotGeneric = useDialog({
        title: 'Operación inválida',
        description: (
            <>
                Para ventas con boletas mayores de <b>S/ 700</b>, el cliente no
                puede ser <b>genérico</b>
            </>
        )
    })

    const toast = useToast()
    const { push } = useHistory()
    const { subtotal, discount, total, selectedItems, saveSelectedItems } =
        useSelectedItems()

    const [openModal, setOpenModal] = useState(false)
    const [dataModal, setDataModal] = useState<DataModalResult>({
        message: '',
        receipts: []
    })

    let to = 'PARA COMER'

    if (storagePage.to === 'carry_out') {
        to = 'PARA LLEVAR'
    }
    if (storagePage.to === 'delivery') {
        to = 'DELIVERY'
    }

    const TypeTax = {
        levied: 1, // gravado
        exempt: 2 // exonerado
    }

    async function confirmPayment() {
        const client = storagePage.clientSelected as Client
        if (
            client.type_identification_document_id !== 2 &&
            storagePage.type === 'invoice'
        ) {
            dialogInvalidOperationRUC()
            return
        }

        if (
            storagePage.alias.trim() === '' &&
            (storagePage.to === 'carry_out' || storagePage.to === 'delivery')
        ) {
            dialogAlias()
            return
        }

        if (
            total > 700 &&
            client.id === 1 &&
            storagePage.type !== null &&
            storagePage.type !== 'free'
        ) {
            dialogRequiredClientNotGeneric()
            return
        }
        const printerConfig = getPrinterConfig()
        if (!printerConfig) {
            toast({
                title: 'Lo sentimos, se requiere configurar la impresora para realizar pagos',
                position: 'top',
                status: 'error'
            })
            return
        }

        const body = getBodyPayment(
            subtotal,
            total,
            selectedItems,
            session ? session.user.id : null
        )
        modal(ContentModalLoading, {
            cleaned: true,
            closeOnOverlayClick: false,
            size: 'md'
        })

        let result: Result<ResponsePostOrder>

        if (order_id) {
            if (account_id) {
                const nextBody: Partial<typeof body> = { ...body }
                delete nextBody.items
                result = await post<ResponsePostOrder>(
                    `/api/orders/${order_id}/confirmation?account_order_id=${account_id}`,
                    nextBody
                )
            } else {
                const nextBody: Partial<typeof body> = { ...body }
                delete nextBody.items
                result = await post<ResponsePostOrder>(
                    `/api/orders/${order_id}/confirmation`,
                    nextBody
                )
            }
        } else {
            result = await post<ResponsePostOrder>('/api/orders', body)
        }

        if (storagePage.type === 'free') {
            //Valida si aún hay subcuentas activas
            if (result.data.result.result.change_status === true) {
                done(true)
            } else {
                done()
            }
            close()
            return
        }

        if (result.data) {
            if (storagePage.type) {
                const bodySunat: BodyGenerateSunatVoucher = {
                    issue_date: dayjs().format(),
                    order_payment_id:
                        result.data.result.result.order_payment.id,
                    type_voucher_id: storagePage.type === 'ticket' ? 1 : 2,
                    client_id: storagePage.clientSelected?.id as number,
                    printer_id: Number(printerConfig.printer_id),
                    type_tax: TypeTax[storagePage.typeTax]
                }
                const res = await post<ResponseGenerateSunatVoucher>(
                    '/api/sunat_vouchers/generate_sunat_voucher',
                    bodySunat
                )

                if (res.data) {
                    await Promise.all([
                        post('/api/sunat_vouchers/send_sunat_voucher', {
                            filename: res.data.result.result.xml_name
                        })
                    ])
                } else {
                    toast({
                        title: res.error?.message,
                        status: 'error',
                        position: 'top'
                    })
                }
            }

            //Si no seleccionada nada (nota de venta)
            await Promise.all([
                post('/api/v2/printers/print_sale_note', {
                    order_payment_id:
                        result.data.result.result.order_payment.id,
                    printer_id: Number(printerConfig.printer_id)
                })
            ])

            //Valida si aún hay subcuentas activas
            if (result.data.result.result.change_status === true) {
                done(true)
            } else {
                done()
            }
        } else {
            //Valida si existen subcaracteristicas con insumos agotados
            if (
                result.error?.type === 'development' &&
                result.error?.response
            ) {
                const error: Exception<string[]> = result.error
                setOpenModal(true)
                setDataModal({
                    message: error.message,
                    receipts: error.response
                })
                close()
                return
            }

            toast({
                title: result.error?.message,
                status: 'error',
                position: 'top'
            })
        }

        close()
    }

    function done(withoutAccounts?: boolean) {
        saveSelectedItems([])
        if (order_id) {
            if (account_id) {
                if (withoutAccounts == true) {
                    push('/by_tables')
                } else push(`/orders/${order_id}/confirm/split`)
            } else {
                push('/by_tables')
            }
        } else {
            push('/quick_sale')
        }
        toast({
            title: 'Venta registrada',
            status: 'success',
            position: 'top',
            isClosable: true
        })
        clearStates()
    }

    let cash = 0
    for (let i = 0; i < storagePage.cash.length; i++) {
        cash += storagePage.cash[i].amount
    }

    let card = 0
    for (let i = 0; i < storagePage.card.length; i++) {
        card += storagePage.card[i].amount
    }

    let transfer = 0
    for (let i = 0; i < storagePage.transfer.length; i++) {
        transfer += storagePage.transfer[i].amount
    }

    function OpenSplitPayment() {
        push(`/orders/${order_id}/confirm/split`)
    }

    return {
        openModal,
        dataModal,
        setOpenModal,
        subtotal,
        discount,
        total,
        to,
        alias: storagePage.alias,
        confirmPayment,
        accumulatedPayment: cash + card + transfer,
        typeOrder: storagePage.type,
        OpenSplitPayment,
        order_id,
        account_id
    }
}
