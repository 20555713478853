import useTab from '../../hooks/useTab'
import useCategory from './Category/useCategory'
import useDestiny from './Destiny/useDestiny'
import useItem from './Letter/useItem'
import useFeature from './Feature/useFeature'
import useSupply from './Supply/useSupply'
import useSupplyCategory from './SupplyCategory/useSupplyCategory'

export default function useControl() {
    const openModalCategory = useCategory()
    const openModalDestiny = useDestiny()
    const openModalItem = useItem()
    const openModalFeature = useFeature()
    const openModalSupply = useSupply()
    const openModalSupplyCategory = useSupplyCategory()
    const props = useTab('item')

    function openModal() {
        if (props.value === 'item') {
            openModalItem()
        }
        if (props.value === 'category') {
            openModalCategory()
        }
        if (props.value === 'destiny') {
            openModalDestiny()
        }
        if (props.value === 'supply') {
            openModalSupply()
        }
        if (props.value === 'supply_category') {
            openModalSupplyCategory()
        }
        if (props.value === 'feature') {
            openModalFeature()
        }
    }

    function getTextButton() {
        switch (props.value) {
            case 'item':
                return 'Nuevo producto'
            case 'category':
                return 'Nueva categoría'
            case 'supply':
                return 'Nuevo insumo'
            case 'supply_category':
                return 'Nueva categoría de insumo'
            case 'feature':
                return 'Nueva característica'
            default:
                return 'Nuevo destino de preparación'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props
    }
}
