import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    SelectProps,
    Select,
    Grid,
    Text,
    Switch,
    HStack,
    Box,
    useRadio,
    useRadioGroup,
    Icon,
    Button
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import AddIcon from '../../../components/Icon/AddIcon'
import Trash from '../../../components/Icon/Trash'
import ErrorHelper from '../../../components/ErrorHelper'
import NumberField from '../../../components/NumberField'
import { useGet } from '../../../hooks/useHttp'
import TypeMeasure from '../../../models/type_measure'
import { formSupply } from '../storage'
import SupplyCategory from '../../../models/supply_category'

export default function FormSupply() {
    return (
        <>
            <>
                <Text mb={3} fontWeight="bold" fontSize="20px">
                    Información General
                </Text>
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                    <Name />
                    <Category />
                </Grid>
            </>
            <PresentationContainer />
        </>
    )
}

function Name() {
    const { props, helper, error } = formSupply.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>Nombre</FormLabel>
            <Input
                {...props}
                placeholder="Ingresar el nombre del insumo"
                autoFocus
                maxLength={180}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Stock() {
    const { props, helper, error } =
        formSupply.useField<InputProps>('minimal_stock')
    const { value, setValue } = formSupply.useValue('has_inventory_control')

    const id = formSupply.store().id

    return (
        <FormControl mb={4}>
            <FormControl display="flex" alignItems="center">
                <FormLabel>¿Control de Stock?</FormLabel>
                <Switch
                    isChecked={value}
                    onChange={e => setValue(e.target.checked)}
                />
            </FormControl>

            {value && !id && (
                <>
                    <NumberField
                        {...props}
                        placeholder="Ingresar stock mínimo"
                    />
                    <ErrorHelper helper={helper || error} />
                </>
            )}
        </FormControl>
    )
}

function ConvertedMeasureQuantity() {
    const { props, helper, error } =
        formSupply.useField<InputProps>('converted_quantity')

    return (
        <FormControl mb={4}>
            <FormLabel>Cantidad</FormLabel>
            <NumberField {...props} placeholder="Cantidad" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function ConvertedTypeMeasure() {
    const { data } = useGet<TypeMeasure[]>('/api/type_measures?sort=name', [])
    const { props, helper } = formSupply.useField<SelectProps>(
        'converted_type_measure_id'
    )

    return (
        <FormControl>
            <FormLabel>Unidad de medida</FormLabel>
            <Select {...props} placeholder="Seleccionar unidad de medida">
                {data.result.map(typeMeasure => (
                    <option key={typeMeasure.id} value={typeMeasure.id}>
                        {typeMeasure.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function ConvertedMeasureContainer() {
    return (
        <Box display="flex" flexDirection="column">
            <Box
                mb={4}
                bg="#F6F6F6"
                color="1A202C"
                py="12px"
                width="100%"
                borderRadius="md"
                textAlign="center"
            >
                1 unidad equivale a
            </Box>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <ConvertedMeasureQuantity />
                <ConvertedTypeMeasure />
            </Grid>
        </Box>
    )
}

function ConvertedMeasure() {
    const { value, setValue } = formSupply.useValue('converted_measure')

    return (
        <>
            <FormControl mb={2}>
                <FormControl display="flex" alignItems="center">
                    <FormLabel>¿Convertir medida?</FormLabel>
                    <Switch
                        isChecked={value}
                        onChange={e => setValue(e.target.checked)}
                    />
                </FormControl>
            </FormControl>
            {value && <ConvertedMeasureContainer />}
        </>
    )
}

function Unity() {
    const { data } = useGet<TypeMeasure[]>('/api/type_measures?sort=name', [])
    const { props, helper } =
        formSupply.useField<SelectProps>('type_measure_id')

    return (
        <>
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                <FormControl>
                    <FormLabel>Unidad de medida</FormLabel>
                    <Select
                        {...props}
                        placeholder="Seleccionar unidad de medida"
                    >
                        {data.result.map(typeMeasure => (
                            <option key={typeMeasure.id} value={typeMeasure.id}>
                                {typeMeasure.name}
                            </option>
                        ))}
                    </Select>
                    <ErrorHelper helper={helper} />
                </FormControl>
                <Stock />
            </Grid>
            {+props.value == 4 && <ConvertedMeasure />}
        </>
    )
}

function Category() {
    const { data } = useGet<SupplyCategory[]>(
        '/api/supply_categories?sort=name',
        []
    )
    const { props, helper } =
        formSupply.useField<SelectProps>('supply_category_id')

    return (
        <FormControl mb={4}>
            <FormLabel>Categoría</FormLabel>
            <Select {...props} placeholder="Seleccionar categoría del insumo">
                {data.result.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function PresentationContainer() {
    const { value, setValue } = formSupply.useValue('has_presentation')

    const options = ['no', 'yes']

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'presentation',
        defaultValue: value ? 'yes' : 'no',
        onChange: hasPresentation => {
            setValue(hasPresentation == 'yes')
        }
    })

    const group = getRootProps()

    return (
        <>
            <HStack {...group} mb={4} justifyContent="center">
                {options.map(item => {
                    const radio = getRadioProps({ value: item })
                    return <RadioCard key={item} {...radio} />
                })}
            </HStack>
            {!value && <Unity />}
            {value && <Presentations />}
        </>
    )
}

function RadioCard(props: any) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    const additionalStyles: any = {}
    if (props.value == 'no') {
        additionalStyles.borderLeftRadius = 'md'
    } else if (props.value == 'yes') {
        additionalStyles.borderRightRadius = 'md'
        additionalStyles.borderRightRadius = 'md'
    }

    return (
        <Box as="label" style={{ margin: 0 }}>
            <input {...input} />
            <Box
                {...checkbox}
                {...additionalStyles}
                cursor="pointer"
                borderWidth="1px"
                whiteSpace="nowrap"
                _unchecked={{
                    bg: 'white',
                    color: 'black',
                    borderColor: '#E2E8F0'
                }}
                _checked={{
                    bg: '#29A6FF',
                    color: 'white',
                    borderColor: '#29A6FF'
                }}
                px="24px"
                py="2px"
            >
                {props.value == 'yes'
                    ? 'Con presentaciones'
                    : 'Sin presentaciones'}
            </Box>
        </Box>
    )
}

function Presentations() {
    const { value, setValue } = formSupply.useValue('supply_presentations')
    const { data: type_measures } = useGet<TypeMeasure[]>(
        '/api/type_measures?sort=name',
        []
    )

    const addPresentation = () => {
        const newValue = value ? value : []
        newValue.push({
            id: null,
            name: '',
            minimal_stock: '',
            has_inventory_control: false,
            type_measure_id: '',
            converted_measure: false,
            converted_quantity: '',
            converted_type_measure_id: ''
        })
        setValue(newValue)
    }

    const handleChange = (
        index: number,
        e: ChangeEvent<HTMLInputElement> | any
    ) => {
        if (
            e.target.name === 'has_inventory_control' ||
            e.target.name === 'converted_measure'
        ) {
            value[index][e.target.name] = e.target.checked
        } else {
            value[index][e.target.name] = e.target.value
        }
        setValue([...value])
    }

    const handleDelete = (index: number) => {
        const newValue = []
        for (let i = 0; i < value.length; i++) {
            if (i !== index) {
                newValue.push(value[i])
            }
        }
        setValue([...newValue])
    }

    return (
        <Box>
            {value.map((supply_presentation: any, index: number) => (
                <Box
                    key={index}
                    width="100%"
                    bg="white"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="#E2E8F0"
                    py="26px"
                    px="32px"
                    mb={4}
                    position="relative"
                >
                    <Button
                        position="absolute"
                        top="4px"
                        right="4px"
                        bg="transparent"
                        padding="4px 6px"
                        cursor="pointer"
                        _hover={{ bg: 'transparent' }}
                        _focus={{
                            bg: 'transparent',
                            boxShadow: 'none'
                        }}
                        _active={{ bg: 'transparent' }}
                        onClick={() => handleDelete(index)}
                    >
                        <Icon
                            width="18px"
                            height="24px"
                            as={Trash}
                            fill="#FE6A5E"
                        />
                    </Button>
                    <FormControl mb={4}>
                        <FormLabel>Nombre de presentación</FormLabel>
                        <Input
                            value={supply_presentation.name}
                            name="name"
                            placeholder="Ingresar el nombre de presentación"
                            onChange={e => handleChange(index, e)}
                        />
                        <ErrorHelper
                            helper={formSupply.getErrorField(
                                `supply_presentation.${index}.name`
                            )}
                        />
                    </FormControl>
                    <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                        <FormControl>
                            <FormLabel>Unidad de medida</FormLabel>
                            <Select
                                name="type_measure_id"
                                value={supply_presentation.type_measure_id}
                                placeholder="Seleccionar unidad de medida"
                                onChange={e => handleChange(index, e)}
                            >
                                {type_measures.result.map(typeMeasure => (
                                    <option
                                        key={typeMeasure.id}
                                        value={typeMeasure.id}
                                    >
                                        {typeMeasure.name}
                                    </option>
                                ))}
                            </Select>
                            <ErrorHelper
                                helper={formSupply.getErrorField(
                                    `supply_presentation.${index}.type_measure_id`
                                )}
                            />
                        </FormControl>
                        <FormControl>
                            <FormControl display="flex" alignItems="center">
                                <FormLabel>¿Control de Stock?</FormLabel>
                                <Switch
                                    name="has_inventory_control"
                                    isChecked={
                                        supply_presentation.has_inventory_control
                                    }
                                    onChange={e => handleChange(index, e)}
                                />
                            </FormControl>
                            {supply_presentation.has_inventory_control && (
                                <>
                                    <NumberField
                                        name="minimal_stock"
                                        value={
                                            supply_presentation.minimal_stock
                                        }
                                        onChange={(e: any) =>
                                            handleChange(index, e)
                                        }
                                        placeholder="Ingresar stock mínimo"
                                    />
                                    <ErrorHelper
                                        helper={formSupply.getErrorField(
                                            `supply_presentation.${index}.minimal_stock`
                                        )}
                                    />
                                </>
                            )}
                        </FormControl>
                    </Grid>
                    {+supply_presentation.type_measure_id == 4 && (
                        <>
                            <FormControl mb={2}>
                                <FormControl display="flex" alignItems="center">
                                    <FormLabel>¿Convertir medida?</FormLabel>
                                    <Switch
                                        name="converted_measure"
                                        isChecked={
                                            supply_presentation.converted_measure
                                        }
                                        onChange={e => handleChange(index, e)}
                                    />
                                </FormControl>
                            </FormControl>
                            {supply_presentation.converted_measure && (
                                <Box display="flex" flexDirection="column">
                                    <Box
                                        mb={4}
                                        bg="#F6F6F6"
                                        color="1A202C"
                                        py="12px"
                                        width="100%"
                                        borderRadius="md"
                                        textAlign="center"
                                    >
                                        1 unidad equivale a
                                    </Box>
                                    <Grid
                                        templateColumns="repeat(2, 1fr)"
                                        gap={6}
                                        mb={4}
                                    >
                                        <FormControl mb={4}>
                                            <FormLabel>Cantidad</FormLabel>
                                            <NumberField
                                                name="converted_quantity"
                                                value={
                                                    supply_presentation.converted_quantity
                                                }
                                                onChange={(e: any) =>
                                                    handleChange(index, e)
                                                }
                                                placeholder="Cantidad"
                                            />
                                            <ErrorHelper
                                                helper={formSupply.getErrorField(
                                                    `supply_presentation.${index}.converted_quantity`
                                                )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>
                                                Unidad de medida
                                            </FormLabel>
                                            <Select
                                                name="converted_type_measure_id"
                                                value={
                                                    supply_presentation.converted_type_measure_id
                                                }
                                                placeholder="Seleccionar unidad de medida"
                                                onChange={e =>
                                                    handleChange(index, e)
                                                }
                                            >
                                                {type_measures.result.map(
                                                    typeMeasure => (
                                                        <option
                                                            key={typeMeasure.id}
                                                            value={
                                                                typeMeasure.id
                                                            }
                                                        >
                                                            {typeMeasure.name}
                                                        </option>
                                                    )
                                                )}
                                            </Select>
                                            <ErrorHelper
                                                helper={formSupply.getErrorField(
                                                    `supply_presentation.${index}.converted_type_measure_id`
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            ))}
            <Button
                width="100%"
                bg="white"
                borderWidth="1px"
                borderColor="#E2E8F0"
                borderRadius="md"
                textAlign="center"
                py="11px"
                onClick={addPresentation}
            >
                <Icon as={AddIcon} fill="default" mr="11px" />
                Agregar presentación
            </Button>
        </Box>
    )
}
