import { Box, SimpleGrid } from '@chakra-ui/react'
import { ReactNode } from 'react'
import './purchase.css'
import Row from './Row'
import Pagination from '../../../lib/list/Pagination'
import { listPurchase } from '../storage'

export default function Purchase() {
    return (
        <>
            <Box className="grid-table list-responsive">
                <SimpleGrid
                    columns={6}
                    textAlign="center"
                    color="gray.600"
                    className="thead"
                >
                    <Label># Compra</Label>
                    <Label>Fecha</Label>
                    <Label>Tipo de comprobante</Label>
                    <Label>N° de comprobante</Label>
                    <Label>Proveedor</Label>
                    <Label>Almacén</Label>
                </SimpleGrid>
                <Row />
            </Box>
            <Pagination control={listPurchase} />
        </>
    )
}

function Label({ children }: { children: ReactNode }) {
    return (
        <Box
            fontSize="sm"
            fontWeight="bold"
            py="3"
            alignSelf={'center'}
            className="th"
        >
            {children}
        </Box>
    )
}
