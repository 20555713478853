import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import { useRender } from 'redity'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import useSelectedItems from '../hooks/useSelectedItems'

export default function ResultPay() {
    useRender(Keys.RESULT_PAY)
    const { total } = useSelectedItems()

    let cash = 0
    for (let i = 0; i < storagePage.cash.length; i++) {
        cash += storagePage.cash[i].amount
    }
    let card = 0
    for (let i = 0; i < storagePage.card.length; i++) {
        card += storagePage.card[i].amount
    }
    let transfer = 0
    for (let i = 0; i < storagePage.transfer.length; i++) {
        transfer += storagePage.transfer[i].amount
    }

    const accumulatedPayment = cash + card + transfer
    const lack = total - accumulatedPayment
    const turned = accumulatedPayment - total

    return (
        <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacingX={{ base: 0, md: 10 }}
            mt={{
                xl: 12,
                lg: 5,
                base: 3
            }}
        >
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Pago acumulado</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {accumulatedPayment.toFixed(2)}
                </Text>
            </Flex>
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Falta</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {lack < 0 ? '0.00' : lack.toFixed(2)}
                </Text>
            </Flex>
            <Flex
                flexDirection={{ base: 'row', md: 'column' }}
                justifyContent={'space-between'}
            >
                <Text fontSize={{ base: 'lg', md: 'xl' }}>Vuelto total</Text>
                <Text
                    fontWeight="bold"
                    fontSize={{
                        xl: '4xl',
                        lg: '2xl'
                    }}
                    alignSelf={{ base: 'center', md: 'flex-start' }}
                >
                    S/ {turned < 0 ? '0.00' : turned.toFixed(2)}
                </Text>
            </Flex>
        </SimpleGrid>
    )
}
