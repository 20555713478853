import useTab from '../../hooks/useTab'
import useProvider from './Provider/useProvider'
import usePurchase from './Purchase/usePurchase'

export default function useControl() {
    const openModalProvider = useProvider()
    const openModalPurchase = usePurchase()
    const props = useTab('purchase')

    function openModal() {
        if (props.value === 'provider') {
            openModalProvider()
        }
        if (props.value === 'purchase') {
            openModalPurchase()
        }
    }

    function getTextButton() {
        switch (props.value) {
            case 'provider':
                return 'Nuevo proveedor'
            case 'purchase':
                return 'Nueva compra'
            default:
                return 'Nuevo destino de preparación'
        }
    }

    return {
        openModal,
        getTextButton,
        tabProps: props
    }
}
