import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import { DataItemsCourtesy } from '../../types'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import StatDivider from '../../Components/StatDivider'
import ViewDetailButton from '../../Components/ViewDetailButton'

export default function CourtesyDetail() {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    const data: DataItemsCourtesy[] = []
    cashboxMovements.map(i => data.push(...i.dataItemsCourtesy))
    const quantity = data.map(i => i.quantity).reduce((a, b) => a + b, 0)
    const total = cashboxMovements
        .map(i => i.totalSaleItemsCourtesy)
        .reduce((a, b) => a + b, 0)
    return (
        <Card
            direction="column"
            gridGap={4}
            w={{ base: 'full', md: '49.5%', lg: '50%' }}
        >
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Detalle de cortesía
            </Text>
            <StatCard>
                <StatBox label={'Cant. productos'} value={quantity} />
                <StatDivider />
                <StatBox label={'Total'} value={total} withSymbol={true} />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Fecha</Th>
                                <Th>Cant.</Th>
                                <Th>Producto</Th>
                                <Th>P.U.</Th>
                                <Th>Total</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((item, i) => (
                                <Tr key={i}>
                                    <Td>{item.date}</Td>
                                    <Td>{item.quantity}</Td>
                                    <Td>{item.name}</Td>
                                    <Td isNumeric>
                                        {item.unitCost.toFixed(2)}
                                    </Td>
                                    <Td isNumeric>{item.total.toFixed(2)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th colSpan={4}>Total de cortesía</Th>
                                <Th isNumeric>S/{total.toFixed(2)}</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
