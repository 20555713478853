import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Text
} from '@chakra-ui/react'
import { FormEvent } from 'react'
import ErrorHelper from '../../../../components/ErrorHelper'
import Pencil from '../../../../components/Icon/Pencil'
import ModalFormClient from '../../../../components/ModalFormClient'
import ModalSelectClient from '../../../../components/ModalSelectClient'
import colors from '../../../../config/theme/colors'
import Client from '../../../../models/client'
import TypeIdentificationDocument from '../../../../utilities/type_identification_document'
import TypeField from './TypeField'

export default function Content({
    client,
    onSubmit,
    setClient,
    helper
}: {
    client: Client
    onSubmit: (ev: FormEvent<HTMLFormElement>) => void
    setClient: (client: Client) => void
    helper: string
}) {
    return (
        <>
            <form onSubmit={onSubmit} id="form-emit">
                <TypeField />
                <input type="hidden" value={client.id} name="client_id" />
            </form>
            <FormControl mb={4} position="relative">
                <FormLabel>Cliente</FormLabel>
                <ModalSelectClient
                    element={click => (
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            w="full"
                            bg={colors['base-3']}
                            px="4"
                            cursor="pointer"
                            py="2"
                            onClick={click}
                        >
                            <Box cursor="pointer">
                                <Text fontSize="xl">
                                    {client.type_identification_document_id ===
                                    2
                                        ? client.business_name
                                        : `${client.firstname} ${client.lastname}`}
                                </Text>
                                <Text>
                                    <Text as="b">
                                        {
                                            TypeIdentificationDocument[
                                                client
                                                    .type_identification_document_id
                                            ]
                                        }
                                        :{' '}
                                    </Text>
                                    {client.number}
                                </Text>
                            </Box>
                        </Flex>
                    )}
                    onSelect={setClient}
                />
                <ModalFormClient
                    element={click => (
                        <IconButton
                            icon={<Pencil fill="white" />}
                            aria-label="Edición"
                            bg={colors.orange}
                            size="sm"
                            onClick={client.id === 1 ? () => null : click}
                            position="absolute"
                            opacity={client.id === 1 ? '.6' : '1'}
                            top="12"
                            right="4"
                            _hover={{}}
                        />
                    )}
                    edition={client}
                    onDone={setClient}
                />
                <ErrorHelper helper={helper} />
            </FormControl>
        </>
    )
}
