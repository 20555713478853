import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Provider from '../../../models/provider'
import { formProvider, listProvider } from '../storage'
import FormProvider from './FormProvider'

export default function useProvider(provider?: Provider) {
    const { validation, observer, submit } = useSubmit(formProvider, {
        path: provider ? `/api/providers/${provider.id}` : '/api/providers',
        method: provider ? 'put' : 'post',
        done: () => listProvider.load()
    })

    validation((v, f) => {
        v.document_type.isEmpty('Es requerido el tipo de documento')
        if (['1', '2'].includes(String(f.document_type))) {
            v.document_number.isEmpty('Es requerido el número de documento')
            v.document_number.condition((value: any) => {
                return !/^\d+$/.test(value)
                    ? 'El número de documento debe ser numérico'
                    : ''
            })
            if (String(f.document_type) == '1') {
                v.document_number.condition((value: any) => {
                    return value.length !== 8 ? 'Debe contener 8 dígitos' : ''
                })
            } else if (String(f.document_type) == '2') {
                v.document_number.condition((value: any) => {
                    return value.length !== 11 ? 'Debe contener 11 dígitos' : ''
                })
            }
        }
        if (String(f.document_type) == '2') {
            v.name.isEmpty('Es requerido la razón social')
        }
        if (['1', '10'].includes(String(f.document_type))) {
            v.name.isEmpty('Es requerido los nombres')
            v.lastname.isEmpty('Es requerido los apellidos')
        }
        if (f.email.length > 0) {
            v.email.email('Formato de email incorrecto')
        }
    })

    observer<FormSubmit>(f => ({
        document_type: f.document_type,
        document_number: f.document_number,
        name: f.name,
        lastname: f.lastname,
        email: f.email,
        phone: f.phone,
        address: f.address
    }))

    function openModal() {
        if (provider) {
            formProvider.store({
                document_type: provider.document_type || '',
                document_number: provider.document_number || '',
                name: provider.name || '',
                lastname: provider.lastname || '',
                email: provider.email || '',
                phone: provider.phone || '',
                address: provider.address || ''
            })
            formProvider.init(formProvider.store)
        } else {
            formProvider.init()
        }
        modal(FormProvider, {
            title: provider ? 'Editar Proveedor' : 'Nuevo Proveedor',
            size: '2xl',
            onSubmit: submit
        })
    }

    return openModal
}

interface FormSubmit {
    name: string
}
