import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { storagePage } from '../../storage'
import { DataTypePayments } from '../../types'
import Card from '../../Components/Card'

export default function PaymentMethodReport() {
    const { cashboxMovements } = storagePage

    const data: DataTypePayments[] = []

    cashboxMovements.map(i => data.push(...i.dataTypePayments))

    const newData = Object.values(
        data.reduce((agg: any, typePayment) => {
            if (!agg[typePayment.name]) {
                agg[typePayment.name] = { name: typePayment.name, value: 0 }
            }
            agg[typePayment.name].value += typePayment.value
            return agg
        }, {})
    )

    const total = data.map(i => i.value).reduce((a, b) => a + b, 0)

    return (
        <Card direction="column" gridGap={4}>
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Formas de pago
            </Text>
            <TableContainer>
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Tipo de pago</Th>
                            <Th isNumeric>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {newData.map((typePayment: any, i) => (
                            <Tr key={i}>
                                <Td>{typePayment.name}</Td>
                                <Td isNumeric>
                                    {typePayment.value.toFixed(2)}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th>Total recaudado</Th>
                            <Th isNumeric>S/{total.toFixed(2)}</Th>
                        </Tr>
                    </Tfoot>
                </Table>
            </TableContainer>
        </Card>
    )
}
