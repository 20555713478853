import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import SupplyCategory from '../../../models/supply_category'
import { listSupplyCategory } from '../storage'

export default function DeleteSupplyCategory({
    supply_category
}: {
    supply_category: SupplyCategory
}) {
    return (
        <Delete
            title="Eliminar recurso"
            path={`/api/supply_categories/${supply_category.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => {
                listSupplyCategory.setQuery('page', 1)
                listSupplyCategory.load()
            }}
        >
            ¿Esta seguro de eliminar el recurso <b>{supply_category.name}</b>?
        </Delete>
    )
}
