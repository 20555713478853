import {
    Button,
    Flex,
    Icon,
    FormControl,
    FormLabel,
    Modal,
    ModalContent,
    ModalOverlay,
    Text,
    Textarea
} from '@chakra-ui/react'
import colors from '../../config/theme/colors'
import { ModalAnullProps } from './types'
import useAnull from './useAnnul'
import ErrorHelper from '../ErrorHelper'
import Block from '../Icon/Block'

export default function ModalAnnul({
    element,
    orderPaymentId,
    onDone
}: ModalAnullProps) {
    const {
        setOpen,
        isOpen,
        submitHandler,
        isLoading,
        helpers,
        changeHandler
    } = useAnull(orderPaymentId, onDone)

    return (
        <>
            {element(() => setOpen(true))}
            <Modal
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                size="md"
                isCentered
            >
                <ModalOverlay />
                <ModalContent px={10} pb={9} pt={4}>
                    <form noValidate onSubmit={submitHandler}>
                        <Icon
                            as={Block}
                            fill="error"
                            mx="auto"
                            fontSize="4xl"
                            mt={4}
                            my={3}
                            display="block"
                        />
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="5"
                        >
                            Anular venta
                        </Text>
                        <Text textAlign="center" pb={3}>
                            ¿Está seguro de anular la venta?
                        </Text>
                        <FormControl isRequired>
                            <FormLabel>Ingresar motivo</FormLabel>
                            <Textarea
                                name="comment"
                                placeholder="Ingresar motivo de anulación"
                                maxLength={300}
                                onChange={changeHandler}
                                disabled={isLoading}
                            />
                            <ErrorHelper helper={helpers.comment} />
                        </FormControl>
                        <Flex mt="8" justifyContent="space-between">
                            <Button
                                variant="outline"
                                w="36"
                                onClick={() => setOpen(false)}
                            >
                                No, cancelar
                            </Button>
                            <Button
                                w="36"
                                bg={colors.error}
                                color="white"
                                type="submit"
                                isLoading={isLoading}
                            >
                                Sí, anular
                            </Button>
                        </Flex>
                    </form>
                </ModalContent>
            </Modal>
        </>
    )
}
