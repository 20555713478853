import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useProvider from './useProvider'
import Provider from '../../../models/provider'

export default function Edition({ provider }: { provider: Provider }) {
    const openModal = useProvider(provider)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
