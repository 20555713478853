import dayjs from 'dayjs'
import { listPurchase } from '../storage'
import { Box, Link, SimpleGrid } from '@chakra-ui/react'
import ModalDetail from './ModalDetail'

export default function Row() {
    const data = listPurchase.useData()

    const voucherTypeLabel = (type?: string) => {
        if (type) {
            if (+type == 1) return 'Boleta'
            if (+type == 2) return 'Factura'
        }
        return 'Ninguno'
    }

    const generateCode = (id: number) => {
        const minLength = 5
        const numberStr = String(id)
        const zeros = minLength - numberStr.length
        return `C${'0'.repeat(zeros)}${numberStr}`
    }

    return (
        <Box>
            {data.map((purchase, i) => (
                <SimpleGrid
                    key={i}
                    columns={6}
                    textAlign="center"
                    className="row tr"
                >
                    <Box color="gray.600" py="4">
                        <ModalDetail
                            purchase={purchase}
                            code={generateCode(purchase.id)}
                            element={click => (
                                <Link
                                    onClick={click}
                                    color="alo-blue"
                                    textDecoration="underline"
                                >
                                    {generateCode(purchase.id)}
                                </Link>
                            )}
                        />
                    </Box>
                    <Box color="gray.600" py="4">
                        {dayjs(purchase.created_at).format('DD/MM/YYYY HH:mm')}
                    </Box>
                    <Box color="gray.600" py="4">
                        {voucherTypeLabel(purchase.voucher_type?.toString())}
                    </Box>
                    <Box color="gray.600" py="4">
                        {purchase.voucher_number
                            ? purchase.voucher_number
                            : '-'}
                    </Box>
                    <Box color="gray.600" py="4">
                        {(purchase.provider?.name ?? '') +
                            ' ' +
                            (purchase.provider?.lastname ?? '').trim()}
                    </Box>
                    <Box color="gray.600" py="4">
                        {purchase.warehouse?.name ?? '-'}
                    </Box>
                </SimpleGrid>
            ))}
        </Box>
    )
}
