import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import Actions from './Actions'
import useInitial from './useInitial'
import dayjs from 'dayjs'

export default function ReportPurchases() {
    useInitial()

    const generateCode = (id: number) => {
        const minLength = 5
        const numberStr = String(id)
        const zeros = minLength - numberStr.length
        return `C${'0'.repeat(zeros)}${numberStr}`
    }

    return (
        <Sidebar>
            <SimpleNav title="Reporte de Detalle de Compras" />
            <Actions />
            <List
                className="table-report"
                control={controlList}
                tbody={(purchase_detail, e) => (
                    <Tr key={e}>
                        <Td>{generateCode(purchase_detail.id)}</Td>
                        <Td>
                            {dayjs(purchase_detail.date).format('DD/MM/YYYY')}
                        </Td>
                        <Td>{purchase_detail.provider_name}</Td>
                        <Td>{purchase_detail.category_name}</Td>
                        <Td textAlign="left">{purchase_detail.name}</Td>
                        <Td>{purchase_detail.type_measure_name}</Td>
                        <Td textAlign="center">{purchase_detail.quantity}</Td>
                        <Td textAlign="right">
                            {purchase_detail.unit_cost.toLocaleString('es-PE', {
                                style: 'currency',
                                currency: 'PEN'
                            })}
                        </Td>
                        <Td textAlign="right">
                            {purchase_detail.total.toLocaleString('es-PE', {
                                style: 'currency',
                                currency: 'PEN'
                            })}
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
