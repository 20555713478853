import { useContext } from 'react'
import RunwayContext from '../context/RunwayContext'

export default function useRunway() {
    const { selectedItems, saveSelectedItems } = useContext(RunwayContext)

    return {
        selectedItems,
        saveSelectedItems
    }
}
