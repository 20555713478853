import { useToast } from '@chakra-ui/react'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSession from '../../../../hooks/useSession'
import { post } from '../../../../lib/http'
import val from '../../../../lib/val'
import { close } from '../../../../lib/modal'
import { storagePage } from '../../storage'

export default function useDialogCancel(order_id: number) {
    const [isOpen, setOpen] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const toast = useToast()
    const { push } = useHistory()
    const { isLogged, session, isAdmin } = useSession()
    const isNotAdmin = !isAdmin()
    const [helpers, setHelpers] = useState<{ motivo?: string }>({})

    async function submitHandler(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault()

        const formElem = ev.target as typeof ev.target & {
            motivo: { value: string }
        }

        const v = val({
            motivo: formElem.motivo.value
        })

        v.motivo.isEmpty('Es requerido ingresar el motivo')

        const result = v.runtest()
        if (result) {
            setHelpers(result)
            return
        }

        setLoading(true)

        const { error } = await post(`/api/orders/${order_id}/request/cancel`, {
            cashbox_id: storagePage.cashbox_id,
            user_id_cancel:
                isLogged && session !== null ? session.user.id : null,
            comment: formElem.motivo.value
        })

        if (error) {
            toast({
                status: 'error',
                position: 'top',
                title: error.message
            })
            setOpen(false)
        } else {
            push('/by_tables')
            toast({
                status: 'success',
                position: 'top',
                title: 'Se ha anulado el pedido',
                isClosable: true
            })
            close()
        }
    }

    function openHandler() {
        setOpen(true)
    }

    function changeHandler(
        ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const name = ev.target.name as keyof typeof helpers
        if (helpers[name]) {
            setHelpers({
                ...helpers,
                [name]: ''
            })
        }
    }

    return {
        isOpen,
        setOpen,
        submitHandler,
        isLoading,
        isNotAdmin,
        setHelpers,
        changeHandler,
        helpers,
        openHandler
    }
}
