import { Table, TableContainer, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import StatDivider from '../../Components/StatDivider'
import ViewDetailButton from '../../Components/ViewDetailButton'

export default function ProofPaymentReport({
    type
}: {
    type: 'note' | 'invoice' | 'ticket'
}) {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    // Datos de notas de venta
    const totalSaleNote = cashboxMovements
        .map(i => i.totalSaleForSalesNote)
        .reduce((a, b) => a + b, 0)

    const totalTransactionsNote = cashboxMovements
        .map(i => i.nTransactionsForSalesNote)
        .reduce((a, b) => a + b, 0)

    const netSaleNote = cashboxMovements
        .map(i => i.netSaleForSalesNote)
        .reduce((a, b) => a + b, 0)

    const taxNote = cashboxMovements
        .map(i => i.igvForSalesNote)
        .reduce((a, b) => a + b, 0)

    // Datos de facturas
    const totalSaleInvoice = cashboxMovements
        .map(i => i.totalSaleForInvoices)
        .reduce((a, b) => a + b, 0)

    const totalTransactionsInvoice = cashboxMovements
        .map(i => i.nTransactionsForInvoices)
        .reduce((a, b) => a + b, 0)

    const netSaleInvoice = cashboxMovements
        .map(i => i.netSaleForInvoices)
        .reduce((a, b) => a + b, 0)

    const taxInvoice = cashboxMovements
        .map(i => i.igvForInvoices)
        .reduce((a, b) => a + b, 0)

    // Datos de boletas
    const totalSaleTicket = cashboxMovements
        .map(i => i.totalSaleForTickets)
        .reduce((a, b) => a + b, 0)

    const totalTransactionsTicket = cashboxMovements
        .map(i => i.nTransactionsForTickets)
        .reduce((a, b) => a + b, 0)

    const netSaleTicket = cashboxMovements
        .map(i => i.netSaleForTickets)
        .reduce((a, b) => a + b, 0)

    const taxTicket = cashboxMovements
        .map(i => i.igvForTickets)
        .reduce((a, b) => a + b, 0)

    let title, total, transactions, netSale, tax
    if (type == 'note') {
        title = 'Notas de venta'
        total = totalSaleNote
        transactions = totalTransactionsNote
        netSale = netSaleNote
        tax = taxNote
    } else if (type == 'invoice') {
        title = 'Facturas'
        total = totalSaleInvoice
        transactions = totalTransactionsInvoice
        netSale = netSaleInvoice
        tax = taxInvoice
    } else {
        title = 'Boletas'
        total = totalSaleTicket
        transactions = totalTransactionsTicket
        netSale = netSaleTicket
        tax = taxTicket
    }

    return (
        <Card direction="column" gridGap={4} w="full">
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                {title}
            </Text>
            <StatCard>
                <StatBox label="N° transacciones" value={transactions} />
                <StatDivider />
                <StatBox label="Total" value={total} withSymbol={true} />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer>
                    <Table size="sm">
                        <Tbody>
                            <Tr>
                                <Td>Venta Neta</Td>
                                <Td isNumeric>{netSale?.toFixed(2)}</Td>
                            </Tr>
                            <Tr>
                                <Td>IGV</Td>
                                <Td isNumeric>{tax?.toFixed(2)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Venta total</Td>
                                <Td isNumeric>{total.toFixed(2)}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
