import ControlList from '../../lib/list/ControlList'
import { ReportPurchaseDetail } from '../../models/report_purchase_detail'

export const controlList = new ControlList<ReportPurchaseDetail>({
    path: '/api/reports/purchase_details',
    theads: [
        '# Compra',
        'Fecha',
        'Proveedor',
        'Categoría',
        'Insumo',
        'Unidad',
        'Cantidad',
        'Costo unitario',
        'Total'
    ]
})
