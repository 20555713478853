import {
    Box,
    Icon,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure
} from '@chakra-ui/react'
import colors from '../../../config/theme/colors'

import Purchase from '../../../models/purchase'
import dayjs from 'dayjs'
import ItemStock from './ItemStock'
import Close from '../../../components/Icon/Close'

export default function ModalDetail({
    element,
    purchase,
    code
}: {
    element: (click: () => void) => JSX.Element
    purchase: Purchase
    code: string
}) {
    const { onOpen, onClose, isOpen } = useDisclosure()

    const voucherTypeLabel = (type?: string) => {
        if (type) {
            if (+type == 1) return 'Boleta'
            if (+type == 2) return 'Factura'
        }
        return 'Ninguno'
    }

    const getDetail = (purchaseSupply: any) => {
        if (purchaseSupply.supply_presentation) {
            return {
                name:
                    purchaseSupply.supply.name +
                    ' - ' +
                    purchaseSupply.supply_presentation.name,
                type_measure: purchaseSupply.supply_presentation.type_measure
            }
        } else {
            return {
                name: purchaseSupply.supply.name,
                type_measure: purchaseSupply.supply.type_measure
            }
        }
    }

    const getTotal = () => {
        let total = 0
        if (purchase.purchase_supplies) {
            for (const v of purchase.purchase_supplies) {
                total += (v.quantity ?? 0) * (v.unit_cost ?? 0)
            }
        }
        return total.toLocaleString('es-PE', {
            style: 'currency',
            currency: 'PEN'
        })
    }

    return (
        <>
            {element(onOpen)}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size="5xl"
                closeOnOverlayClick
            >
                <ModalOverlay />
                <ModalContent px={2} py={4}>
                    <Icon
                        as={Close}
                        fill="gray-3"
                        position="absolute"
                        fontSize="sm"
                        top="30px"
                        right="30px"
                        cursor="pointer"
                        onClick={onClose}
                    />
                    <ModalBody pb={6}>
                        <Text
                            fontSize="2xl"
                            fontWeight="bold"
                            textAlign="center"
                            mb="2"
                        >
                            Compra {code}
                        </Text>
                        <Text
                            textAlign="center"
                            fontSize="sm"
                            color={colors['gray-2']}
                            mb="5"
                        >
                            {dayjs(purchase.created_at).format(
                                'DD/MM/YYYY HH:mm'
                            )}
                        </Text>
                        <Box className="list-responsive750">
                            <SimpleGrid
                                columns={4}
                                border="1px solid"
                                borderColor={colors['gray-3']}
                                borderRadius="xl"
                                padding={4}
                            >
                                <ItemStock
                                    label="Proveedor"
                                    value={
                                        (purchase.provider?.name ?? '') +
                                        ' ' +
                                        (
                                            purchase.provider?.lastname ?? ''
                                        ).trim()
                                    }
                                />
                                <ItemStock
                                    label="Tipo de comprobante"
                                    value={voucherTypeLabel(
                                        purchase.voucher_type?.toString()
                                    )}
                                />
                                <ItemStock
                                    label="N° de comprobante"
                                    value={
                                        purchase.voucher_number
                                            ? purchase.voucher_number
                                            : '-'
                                    }
                                />
                                <ItemStock
                                    label="Almacén"
                                    value={purchase.warehouse?.name ?? '-'}
                                />
                            </SimpleGrid>
                            <Box mt={4} overflowY="auto">
                                <Table size="sm" className="table-detail">
                                    <Thead>
                                        <Tr>
                                            <Th>Producto</Th>
                                            <Th textAlign="center">Unidad</Th>
                                            <Th textAlign="center">Cantidad</Th>
                                            <Th textAlign="right">
                                                Costo unitario
                                            </Th>
                                            <Th textAlign="right">Subtotal</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {purchase.purchase_supplies?.map(
                                            purchaseSupply => (
                                                <Tr key={purchaseSupply.id}>
                                                    <Td textAlign="left">
                                                        {
                                                            getDetail(
                                                                purchaseSupply
                                                            ).name
                                                        }
                                                    </Td>
                                                    <Td textAlign="center">
                                                        {getDetail(
                                                            purchaseSupply
                                                        ).type_measure?.name ??
                                                            '-'}
                                                    </Td>
                                                    <Td textAlign="center">
                                                        {purchaseSupply.quantity ??
                                                            0}
                                                    </Td>
                                                    <Td textAlign="right">
                                                        {(
                                                            purchaseSupply.unit_cost ??
                                                            0
                                                        ).toLocaleString(
                                                            'es-PE',
                                                            {
                                                                style: 'currency',
                                                                currency: 'PEN'
                                                            }
                                                        )}
                                                    </Td>
                                                    <Td textAlign="right">
                                                        {(
                                                            (purchaseSupply.quantity ??
                                                                0) *
                                                            (purchaseSupply.unit_cost ??
                                                                0)
                                                        ).toLocaleString(
                                                            'es-PE',
                                                            {
                                                                style: 'currency',
                                                                currency: 'PEN'
                                                            }
                                                        )}
                                                    </Td>
                                                </Tr>
                                            )
                                        )}
                                    </Tbody>
                                </Table>
                            </Box>
                        </Box>
                        <Box
                            textAlign="right"
                            className="row tr"
                            bg="#F6F6F6 !important"
                            marginTop="32px"
                        >
                            <Box py="4" display="inline-block">
                                Total de compra
                            </Box>
                            <Box
                                display="inline-block"
                                py="4"
                                pr="10px"
                                pl="10px"
                                whiteSpace="nowrap"
                                fontWeight={600}
                                textAlign="right"
                            >
                                {getTotal()}
                            </Box>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
