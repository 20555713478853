import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import SupplyCategory from '../../../models/supply_category'
import useSupplyCategory from './useSupplyCategory'

export default function Edition({
    supply_category
}: {
    supply_category: SupplyCategory
}) {
    const openModal = useSupplyCategory(supply_category)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
