import { Box, Flex } from '@chakra-ui/react'
import Download from './Download'
import Dates from './Dates'

export default function Actions() {
    return (
        <Box mb="5">
            <Flex
                justifyContent={{ base: 'flex-end', xl: 'space-between' }}
                mb="5"
                gridGap={5}
                direction={{ base: 'column', md: 'row' }}
            >
                <Flex gridGap={4} direction={{ base: 'column', md: 'row' }}>
                    <Dates />
                </Flex>
                <Box display={{ base: 'none', xl: 'block' }}>
                    <Download />
                </Box>
            </Flex>
        </Box>
    )
}
