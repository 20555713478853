import { InputProps, SelectProps, TextareaProps } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { renders, useRender } from 'redity'
import { Sttore } from 'sttore'
import { FileFieldProps } from '../../components/FileField/types'
import { Exception } from '../http/type'
import { FieldHookData } from './types'

export default class Fields<T> {
    private info = {
        KeyMain: ''
    }
    protected exception: Exception | null = null
    readonly store: Sttore<T>
    helpers: Partial<Record<keyof T, string>> = {}

    constructor(KeyMain: string, store: Sttore<T>) {
        this.info.KeyMain = KeyMain
        this.store = store
    }

    getErrorField = (keyName: keyof T | string) => {
        if (this.exception && this.exception.errors) {
            return this.exception.errors[keyName as string] || ''
        }
        return ''
    }

    /**
     * Usa componentes Inputs y Select
     * @param fieldName Fieldname of form
     */
    useField<
        K extends InputProps | SelectProps | FileFieldProps | TextareaProps
    >(fieldName: keyof T): FieldHookData<K, T> {
        const r = useRender(this.info.KeyMain, fieldName as string)
        const changeHandler = (ev: ChangeEvent<HTMLInputElement>) => {
            const value = ev.target.value
            this.store.set(fieldName, value as any)
            this.helpers[fieldName] = ''
            if (this.exception && this.exception.errors) {
                this.exception.errors[fieldName as string] = ''
            }
            r()
        }

        return {
            props: {
                value: this.store()[fieldName] as any,
                onChange: changeHandler,
                name: fieldName as string
            } as any,
            helper: this.helpers[fieldName] || '',
            getErrorField: this.getErrorField,
            error: this.getErrorField(fieldName)
        }
    }

    setError(error: Exception) {
        this.exception = error
        renders(this.info.KeyMain)
    }

    useValue<K extends keyof T>(fieldName: K) {
        const r = useRender(this.info.KeyMain, fieldName as string)
        const setValue = (value: T[K]) => {
            this.store.set(fieldName, value)
            this.helpers[fieldName] = ''
            if (this.exception && this.exception.errors) {
                this.exception.errors[fieldName as string] = ''
            }
            r()
        }

        return {
            value: this.store()[fieldName],
            setValue,
            helper: this.helpers[fieldName] || '',
            getErrorField: this.getErrorField,
            error: this.getErrorField(fieldName)
        }
    }
}
