import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import SupplyCategory from '../../../models/supply_category'
import { formSupplyCategory, listSupplyCategory } from '../storage'
import FormResource from './FormSupplyCategory'

export default function useSupplyCategory(supply_category?: SupplyCategory) {
    const { validation, observer, submit } = useSubmit(formSupplyCategory, {
        path: supply_category
            ? `/api/supply_categories/${supply_category.id}`
            : '/api/supply_categories',
        method: supply_category ? 'put' : 'post',
        done: () => listSupplyCategory.load()
    })

    validation(v => {
        v.name.isEmpty('Es requerido el nombre')
    })

    observer<any>(f => ({
        name: f.name
    }))

    function openModal() {
        if (supply_category) {
            formSupplyCategory.store({
                id: supply_category.id.toString() || '',
                name: supply_category.name
            })
            formSupplyCategory.init(formSupplyCategory.store)
        } else {
            formSupplyCategory.init()
        }
        modal(FormResource, {
            title: supply_category
                ? 'Editar Categoría de insumo'
                : 'Nueva Categoría de insumo',
            onSubmit: submit
        })
    }

    return openModal
}

// interface FormSubmit {
//     name: string
//     price: number
//     category_item_id: number
//     image_url: string
// }
