import { Flex, Input, useMediaQuery } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import SelectWithLabel from '../../../components/SelectWithLabel'
import { useGet } from '../../../hooks/useHttp'
import { listSupply } from '../storage'
import SupplyCategory from '../../../models/supply_category'

export default function FiltersPurchase() {
    const { clear, ...props } = listSupply.useSearch()
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)')
    const propsFilter = listSupply.useFilter('supply_category_id')
    const { data } = useGet<SupplyCategory[]>(
        '2>/api/supply_categories?limit=0&page=1&order=asc&sort=name&search=',
        []
    )

    return (
        <Flex gridGap="2" flexDirection={isSmallScreen ? 'column' : 'row'}>
            <Clean value={props.value} onClick={clear}>
                <Input
                    w={isSmallScreen ? '100%' : '80'}
                    placeholder="🏸 Buscar por nombre"
                    {...props}
                />
            </Clean>
            <SelectWithLabel
                {...propsFilter}
                label="Seleccionar categoría:"
                placeholder="Todos"
            >
                {data.result.map(d => (
                    <option key={d.id} value={d.id}>
                        {d.name}
                    </option>
                ))}
            </SelectWithLabel>
        </Flex>
    )
}
