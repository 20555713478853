import ControlList from '../../lib/list/ControlList'
import { StoragePage, SunatVoucherData } from './types'

export const controlList = new ControlList<SunatVoucherData>({
    path: '/api/sunat_vouchers',
    theads: [
        'N°',
        'Fecha',
        'Tipo',
        'Comprobante',
        'Cliente',
        'Monto',
        'Estado',
        '¿Validado SUNAT?',
        'Acciones'
    ]
})

controlList.setQuery('order', 'desc')

export const storagePage: StoragePage = {
    status: null
}
