import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useRunway from '../../hooks/useRunway'
import { get } from '../../lib/http'
import { Result } from '../../lib/http/type'
import CashboxUser from '../../models/cashbox_user'
import Client from '../../models/client'
import PageState from '../../types/pageState'
import orderItemsToSelectedItems from '../QuickSale/utilities/orderItemsToSelectedItems'
import { storagePage } from './storage'
import { Order, PaymentTypeResponse } from './types'

export default function useInitial() {
    const [pageState, setPageState] = useState(PageState.LOADING)
    const { order_id } = useParams<{ order_id?: string }>()
    const { account_id } = useParams<{ account_id?: string }>()
    const { saveSelectedItems } = useRunway()

    async function load() {
        const request: Array<Promise<any>> = [
            get<Client>('/api/clients/1'),
            get<CashboxUser>('/api/cashbox_users/cash_box_user'),
            get<PaymentTypeResponse>('/api/type_payments/all')
        ]

        if (order_id && !account_id) {
            request.push(get<Order>(`/api/account_orders/${order_id}/items`))
        }

        const [result1, result2, result3, result4] = (await Promise.all(
            request
        )) as [
            Result<Client>,
            Result<CashboxUser>,
            Result<PaymentTypeResponse>,
            Result<Order>
        ]

        if (result1.error || result2.error) {
            setPageState(PageState.ERROR)
            return
        }

        if (order_id && !account_id && result4.error) {
            setPageState(PageState.ERROR)
            return
        }

        storagePage.clientSelected = result1.data.result
        storagePage.print_sale_note =
            result2.data.result.business_variables.find(
                v => v.code === '001'
            )?.value
        storagePage.generate_electronic_receipts =
            result2.data.result.business_variables.find(
                v => v.code === '002'
            )?.value

        storagePage.paymentTypes = result3.data.result.result

        if (order_id && !account_id) {
            storagePage.selectedItems = orderItemsToSelectedItems(
                result4.data.result.order_items
            )
            saveSelectedItems(storagePage.selectedItems)
            storagePage.table_id = result4.data.result.table_id
        }

        setPageState(PageState.SUCCESS)
    }

    useEffect(() => {
        load()

        return () => {
            if (order_id) {
                saveSelectedItems([])
            }
        }
    }, [])

    return {
        pageState,
        order_id,
        account_id
    }
}
