import {
    Tr,
    Td,
    Icon,
    Button,
    Text,
    TableContainer,
    Table,
    Thead,
    Th,
    Tbody
} from '@chakra-ui/react'
import ChevronUp from '../../../components/Icon/ChevronUp'
import ChevronDown from '../../../components/Icon/ChevronDown'
import List from '../../../lib/list'
import { listSupply } from '../storage'
import DeleteSupply from './DeleteSupply'
import Edition from './Edition'
import EditionStock from './EditionStock'
import { useState } from 'react'

export default function Supply() {
    const [toggle, setToggle] = useState<any>([])

    const toggleItem = (id: number) => {
        if (toggle.includes(id)) {
            setToggle(toggle.filter((item: number) => item !== id))
        } else {
            setToggle([...toggle, id])
        }
    }

    return (
        <List
            control={listSupply}
            tbody={(supply, e) => (
                <>
                    <Tr key={e}>
                        <Td textAlign="center" width="28">
                            {e}
                        </Td>
                        <Td textAlign="center">{supply.name}</Td>
                        <Td textAlign="center">
                            {supply.type_measure?.name ?? '-'}
                        </Td>
                        <Td textAlign="center">
                            {supply.has_inventory_control ? supply.stock : '-'}
                        </Td>
                        <Td textAlign="center">
                            {!supply.has_presentation && (
                                <EditionStock supply={supply} />
                            )}
                            {supply.has_presentation &&
                                supply.supply_presentations !== undefined &&
                                supply.supply_presentations && (
                                    <Button
                                        bg="transparent"
                                        cursor="pointer"
                                        padding="0px"
                                        height="18px"
                                        _hover={{ bg: 'transparent' }}
                                        _focus={{
                                            bg: 'transparent',
                                            boxShadow: 'none'
                                        }}
                                        _active={{ bg: 'transparent' }}
                                        onClick={() => toggleItem(supply.id)}
                                    >
                                        <Icon
                                            as={
                                                toggle.includes(supply.id)
                                                    ? ChevronUp
                                                    : ChevronDown
                                            }
                                            fill="#437FC6"
                                            width="32px"
                                            height="32px"
                                        />
                                    </Button>
                                )}
                            <Edition supply={supply} />
                            <DeleteSupply supply={supply} />
                        </Td>
                    </Tr>
                    {supply.has_presentation &&
                        supply.supply_presentations !== undefined &&
                        supply.supply_presentations &&
                        toggle.includes(supply.id) && (
                            <Tr key={'presentation_' + e}>
                                <Td colSpan={5} bg="#E2E8F0">
                                    <Text
                                        mb={3}
                                        textAlign="justify"
                                        fontWeight="bold"
                                        fontSize="16px"
                                    >
                                        Presentaciones
                                    </Text>
                                    <TableContainer>
                                        <Table size="sm" variant="simple">
                                            <Thead>
                                                <Tr>
                                                    <Th
                                                        textTransform="unset"
                                                        textAlign="center"
                                                    >
                                                        Nombre
                                                    </Th>
                                                    <Th
                                                        textTransform="unset"
                                                        textAlign="center"
                                                    >
                                                        Unidad
                                                    </Th>
                                                    <Th
                                                        textTransform="unset"
                                                        textAlign="center"
                                                    >
                                                        Stock
                                                    </Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {supply.supply_presentations.map(
                                                    (item, pe) => (
                                                        <Tr key={pe}>
                                                            <Td>{item.name}</Td>
                                                            <Td>
                                                                {item
                                                                    .type_measure
                                                                    ?.name ??
                                                                    '-'}
                                                            </Td>
                                                            <Td>
                                                                {item.stock
                                                                    ? item.stock
                                                                    : '-'}
                                                            </Td>
                                                        </Tr>
                                                    )
                                                )}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Td>
                            </Tr>
                        )}
                </>
            )}
        />
    )
}
