import FormEvent from '../../lib/form_event'
import ControlList from '../../lib/list/ControlList'
import Provider from '../../models/provider'
import Purchase from '../../models/purchase'
import Supply from '../../models/supply'

export const listProvider = new ControlList<Provider>({
    path: '/api/providers',
    theads: ['#', 'Nombre', 'Documento', 'Acciones'], // omited
    removeLimit: true
})

listProvider.setQuery('sort', 'id')

export const formProvider = new FormEvent({
    document_type: '',
    document_number: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    address: ''
})

export const listPurchase = new ControlList<Purchase>({
    path: '/api/purchases',
    theads: [
        '# Compra',
        'Tipo de comprobante',
        'N° de comprobante',
        'Proveedor',
        'Almacén',
        'Acciones'
    ], // omited
    removeLimit: false
})

listPurchase.setQuery('sort', 'created_at')
listPurchase.setQuery('order', 'desc')

export const formPurchase = new FormEvent<any>({
    id: '',
    provider_id: '',
    voucher_type: '',
    voucher_number: '',
    warehouse_id: '',
    total: '',
    purchase_supplies: [],
    add_supplies: false
})

export const formPurchaseSupplies = new FormEvent<any>({
    warehouse_id: '',
    purchase_supplies: []
})

export const listSupply = new ControlList<Supply>({
    path: '/api/purchases/supplies',
    theads: ['#', 'Producto', 'Unidad', 'Almacén', 'Stock actual'],
    removeLimit: false
})

listSupply.setQuery('sort', 'id')
