import { Flex, Text } from '@chakra-ui/react'
import colors from '../../../config/theme/colors'

export default function ItemStock({
    label,
    value
}: {
    label: string
    value: string
}) {
    return (
        <Flex justify="center" alignItems="center" flexDir="column">
            <Text fontSize="xs" fontWeight="bold" color={colors['gray-2']}>
                {label}
            </Text>
            <Text fontWeight="medium" fontSize="md" color={colors['gray-2']}>
                {value}
            </Text>
        </Flex>
    )
}
