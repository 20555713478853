import { Icon } from '@chakra-ui/react'
import Pencil from '../../../components/Icon/Pencil'
import Colors from '../../../config/colors'
import useCategory from './useCategory'
import CategoryItem from '../../../models/category_item'

export default function Edition({ category }: { category: CategoryItem }) {
    const openModal = useCategory(category)

    return (
        <Icon
            fill={Colors.INFO}
            as={Pencil}
            marginRight={2}
            cursor="pointer"
            onClick={openModal}
        />
    )
}
