import { Box, Button, Flex, Heading } from '@chakra-ui/react'
import { useRender } from 'redity'
import CardIcon from '../../../../components/Icon/CardIcon'
import CashIcon from '../../../../components/Icon/CashIcon'
import Transfer from '../../../../components/Icon/Transfer'
import ModalKeyboard from '../../../../components/ModalKeyboard'
import { Keys } from '../../constants'
import usePaymentMethods from '../../hooks/usePaymentMethods'
import useSelectedItems from '../../hooks/useSelectedItems'
import { storagePage } from '../../storage'
import TablePayments from './TablePayments'

export default function PaymentMethods() {
    useRender(Keys.PAYMENTS_METHODS)
    const { cashHandler, cardHandler, transferHandler, disabledButton } =
        usePaymentMethods()

    const { paymentTypes } = storagePage

    function maxAmount() {
        const { total } = useSelectedItems()
        let cash = 0
        for (let i = 0; i < storagePage.cash.length; i++) {
            cash += storagePage.cash[i].amount
        }
        let card = 0
        for (let i = 0; i < storagePage.card.length; i++) {
            card += storagePage.card[i].amount
        }
        let transfer = 0
        for (let i = 0; i < storagePage.transfer.length; i++) {
            transfer += storagePage.transfer[i].amount
        }
        const accumulatedPayment = cash + card + transfer
        const lack = total - accumulatedPayment
        if (lack < 0) return 0
        else return Number(lack.toFixed(2))
    }

    const paymentIcons: any = {
        CashIcon: <CashIcon fill="gray" />,
        CardIcon: <CardIcon fill="gray" />,
        Transfer: <Transfer fill="gray" />
    }

    const attributes: any = {
        Efectivo: {
            title: 'Monto en efectivo',
            maxValue: Infinity,
            done: cashHandler
        },
        Tarjeta: {
            title: 'Monto por tarjeta',
            maxValue: maxAmount(),
            done: cardHandler
        },
        Transferencia: {
            title: 'Monto por transferencia',
            maxValue: maxAmount(),
            done: transferHandler
        }
    }

    return (
        <>
            <Box
                mt={{
                    xl: 4,
                    md: 4,
                    base: 3
                }}
            >
                <Heading fontSize="xl" mb={4}>
                    Métodos de Pago
                </Heading>
                <Flex gridGap={{ base: 2, lg: 3 }}>
                    {paymentTypes.map((paymentType, i) => (
                        <ModalKeyboard
                            key={i}
                            title={attributes[paymentType.name].title}
                            element={click => (
                                <Button
                                    colorScheme="blackAlpha"
                                    variant="outline"
                                    leftIcon={
                                        paymentIcons[paymentType.class_name]
                                    }
                                    size="md"
                                    onClick={click}
                                    disabled={disabledButton}
                                    fontSize={{ base: 'sm', lg: 'md' }}
                                >
                                    {paymentType.name}
                                </Button>
                            )}
                            maxValue={attributes[paymentType.name].maxValue}
                            onlyAmount
                            onDone={attributes[paymentType.name].done}
                            subPaymentTypes={paymentType.sub_payment_types}
                        />
                    ))}
                </Flex>
                <TablePayments />
            </Box>
        </>
    )
}
