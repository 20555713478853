import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { post } from '../../lib/http'
import { PinValues } from './types'

export default function usePin(
    onDone: (pin: string) => void,
    validate?: {
        order_id: number
    }
) {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [isOpen, setOpen] = useState(false)
    const [pinValues, setPinValues] = useState<PinValues>([
        null,
        null,
        null,
        null
    ])
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    const clearHandler = () => {
        setPinValues([null, null, null, null])
    }

    const digitHandler = (number: number) => {
        for (let i = 0; i < pinValues.length; i++) {
            const value = pinValues[i]
            const newPinValues: PinValues = [...pinValues]
            if (value === null) {
                newPinValues[i] = number
                setPinValues(newPinValues)
                break
            }
        }
    }

    const removeLastDigitHandler = () => {
        for (let i = pinValues.length - 1; i >= 0; i--) {
            const value = pinValues[i]
            const newPinValues: PinValues = [...pinValues]
            if (value !== null) {
                newPinValues[i] = null
                setPinValues(newPinValues)
                break
            }
        }
    }

    const okHandler = async () => {
        if (pinValues.find(value => value === null) === null) return
        const pin = pinValues.join('')
        if (validate) {
            setLoading(true)
            const { error } = await post('/api/utils/pin/order', {
                pin,
                order_id: validate.order_id
            })
            setLoading(false)

            if (error) {
                toast({
                    title: error.message,
                    position: 'top',
                    status: 'error'
                })
                setOpen(false)
                return
            }
        }

        clearHandler()
        setOpen(false)
        onDone(pin)
    }

    const openHandler = () => {
        clearHandler()
        setOpen(true)
    }

    return {
        numbers,
        clearHandler,
        removeLastDigitHandler,
        isOpen,
        setOpen,
        digitHandler,
        pinValues,
        okHandler,
        openHandler,
        isLoading
    }
}
