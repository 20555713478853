import useSubmit from '../../../hooks/useSubmit'
import modal from '../../../lib/modal'
import Supply from '../../../models/supply'
import { formSupply, listSupply } from '../storage'
import FormResource from './FormSupply'

export default function useSupply(supply?: Supply) {
    const { validation, observer, submit } = useSubmit(formSupply, {
        path: supply ? `/api/supplies/${supply.id}` : '/api/supplies',
        method: supply ? 'put' : 'post',
        done: () => listSupply.load()
    })

    validation((v, f) => {
        v.name.isEmpty('Es requerido el nombre')
        v.supply_category_id.isEmpty('Es requerido la categoría de insumo')
        if (!f?.has_presentation) {
            v.type_measure_id.isEmpty(
                'Es requerido asignar una unidad de medida'
            )
            if (f?.has_inventory_control && !supply?.id) {
                v.minimal_stock
                    .isEqual(0, 'El stock mínimo debe ser mayor a 0')
                    .isEmpty('Es requerido asignar el stock mínimo')
            }
            if (f?.converted_measure) {
                v.converted_type_measure_id.isEmpty(
                    'Es requerido asignar una unidad de medida convertida'
                )
                v.converted_quantity
                    .isEqual(0, 'La cantidad mínima debe ser mayor a 0')
                    .isEmpty('Es requerido asignar la cantidad')
            }
        }
    })

    observer<any>(f => ({
        name: f.name,
        type_measure_id: Number(f.type_measure_id),
        default_visual_measure_id: Number(f.type_measure_id),
        has_inventory_control: f.has_inventory_control,
        ...(f?.has_inventory_control && {
            minimal_stock: Number(f.minimal_stock)
        }),
        has_presentation: f.has_presentation,
        supply_category_id: Number(f.supply_category_id),
        converted_measure: f.converted_measure,
        converted_quantity:
            !f?.has_presentation && f?.converted_measure
                ? Number(f.converted_quantity)
                : null,
        converted_type_measure_id:
            !f?.has_presentation && f?.converted_measure
                ? Number(f.converted_type_measure_id)
                : null,
        supply_presentations: f.supply_presentations.map((_f: any) => {
            return {
                name: _f.name,
                minimal_stock: Number(_f.minimal_stock),
                has_inventory_control: _f.has_inventory_control,
                type_measure_id: Number(_f.type_measure_id),
                converted_measure: _f.converted_measure,
                converted_quantity: Number(_f.converted_quantity),
                converted_type_measure_id: Number(_f.converted_type_measure_id)
            }
        })
    }))

    function openModal() {
        if (supply) {
            formSupply.store({
                id: supply.id.toString() || '',
                name: supply.name,
                has_inventory_control: supply.has_inventory_control,
                default_visual_measure_id:
                    supply.default_visual_measure_id?.toString() || '',
                minimal_stock: supply.minimal_stock?.toString() || '',
                type_measure_id: supply.type_measure_id?.toString() || '',
                supply_category_id: supply.supply_category_id?.toString() || '',
                has_presentation: supply.has_presentation,
                converted_measure: supply.converted_measure,
                converted_quantity: supply.converted_quantity?.toString() || '',
                converted_type_measure_id:
                    supply.converted_type_measure_id?.toString() || '',
                supply_presentations:
                    supply.supply_presentations?.map(
                        (supply_presentation: any) => {
                            return {
                                id: supply_presentation.id || null,
                                name: supply_presentation.name,
                                minimal_stock:
                                    supply_presentation.minimal_stock?.toString() ||
                                    '',
                                has_inventory_control:
                                    supply_presentation.has_inventory_control,
                                type_measure_id:
                                    supply_presentation.type_measure_id.toString(),
                                converted_measure:
                                    supply_presentation.converted_measure,
                                converted_quantity: Number(
                                    supply_presentation.converted_quantity
                                ),
                                converted_type_measure_id:
                                    supply_presentation.converted_type_measure_id?.toString() ||
                                    ''
                            }
                        }
                    ) || []
            })
            formSupply.init(formSupply.store)
        } else {
            formSupply.init()
        }
        modal(FormResource, {
            title: supply ? 'Editar Insumo' : 'Nuevo Insumo',
            size: '2xl',
            isCentered: false,
            onSubmit: submit
        })
    }

    return openModal
}

// interface FormSubmit {
//     name: string
//     price: number
//     category_item_id: number
//     image_url: string
// }
