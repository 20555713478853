import { Button, Flex, Select } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Provider from './Provider'
import Purchase from './Purchase'
import FiltersProvider from './Provider/FiltersProvider'
import FiltersPurchase from './Purchase/FiltersPurchase'

import useControl from './useControl'
import useInitial from './useInitial'

export default function AdminPurchases() {
    useInitial()
    const { tabProps, openModal, getTextButton } = useControl()

    return (
        <Sidebar>
            <SimpleNav title="Gestión de compras" />
            <Tabs {...tabProps}>
                <Tab value="purchase">Compras</Tab>
                <Tab value="provider">Proveedores</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <option value="purchase">Compras</option>
                    <option value="provider">Proveedores</option>
                </Select>
                <Button variant="alo-blue:md-xl" onClick={openModal}>
                    + Nuevo
                </Button>
            </Flex>
            <Flex
                mt={4}
                mb={4}
                w={{
                    xl: 'auto',
                    lg: tabProps.value === 'item' ? 'full' : 'auto'
                }}
                justifyContent={{
                    md: 'space-between',
                    base: 'flex-end'
                }}
            >
                {tabProps.value === 'purchase' && <FiltersPurchase />}
                {tabProps.value === 'provider' && <FiltersProvider />}
                <Button
                    variant="alo-blue:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'inline' }}
                    ml="2"
                >
                    {getTextButton()}
                </Button>
            </Flex>
            {tabProps.value === 'purchase' && <Purchase />}
            {tabProps.value === 'provider' && <Provider />}
        </Sidebar>
    )
}
