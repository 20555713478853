import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listSupplyCategory } from '../storage'

export default function FiltersSupplyCategory() {
    const { clear, ...props } = listSupplyCategory.useSearch()

    return (
        <Flex>
            <Clean value={props.value} onClick={clear}>
                <Input w="80" placeholder="🏸 Buscar por nombre" {...props} />
            </Clean>
        </Flex>
    )
}
