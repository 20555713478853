import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import { Tr, Td } from '@chakra-ui/react'
import List from '../../lib/list'
import { controlList } from './storage'
import Actions from './Actions'
import useInitial from './useInitial'

export default function ReportCategoryPurchases() {
    useInitial()

    return (
        <Sidebar>
            <SimpleNav title="Reportes de Compras por Categorías de Insumos" />
            <Actions />
            <List
                className="table-report"
                control={controlList}
                tbody={(purchase_category, e) => (
                    <Tr key={e}>
                        <Td>{purchase_category.name}</Td>
                        <Td textAlign="right">
                            {purchase_category.total.toLocaleString('es-PE', {
                                style: 'currency',
                                currency: 'PEN'
                            })}
                        </Td>
                    </Tr>
                )}
            />
        </Sidebar>
    )
}
