import { Button, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { parse } from 'fter'
import { useState } from 'react'
import { useRender } from 'redity'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { get } from '../../../lib/http'
import { ReportPurchaseCategory } from '../../../models/report_purchase_category'
import { controlList } from '../storage'
import DownloadIcon from '../../../components/Icon/Download'

export default function Download() {
    useRender('Download')
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function download() {
        setLoading(true)
        const start_date = controlList.getQuery('start_date') as string
        const end_date = controlList.getQuery('end_date') as string
        const path = parse(
            {
                start_date,
                end_date
            },
            '/api/reports/purchase_categories'
        )

        const { data, error } = await get<ReportPurchaseCategory[]>(path)

        if (error) {
            toast({
                status: 'error',
                title: error.message,
                position: 'top'
            })
            setLoading(false)
            return
        }

        const head: (...values: string[]) => Row[number][] = (...values) =>
            values.map<Row[number]>(val => ({
                value: val,
                fontWeight: 'bold',
                wrap: true,
                alignVertical: 'center',
                align: 'center'
            }))

        const rows: SheetData = [
            [],
            [
                {
                    value: 'REPORTE DE COMPRAS POR CATEGORÍAS DE INSUMOS',
                    fontWeight: 'bold',
                    span: 8,
                    height: 24,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 15
                }
            ],
            [],
            [
                {
                    value: 'Fecha',
                    fontWeight: 'bold'
                },
                {
                    value: `${dayjs(start_date, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY'
                    )} - ${dayjs(end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
                    span: 3
                }
            ],
            [],
            [],
            head('Categoría', 'Total')
        ]

        let total = 0
        data.result.forEach(purchase_category => {
            total += purchase_category.total
            rows.push([
                {
                    value: purchase_category.name,
                    type: String
                },
                {
                    value: purchase_category.total,
                    type: Number,
                    format: '[$S/]#,##0.00'
                }
            ])
        })
        rows.push([
            {},
            {
                value: total,
                type: Number,
                fontWeight: 'bold',
                format: '[$S/]#,##0.00'
            }
        ])

        const date = dayjs().format('YYYY_MM_DD_HH_mm_ss')

        await writeXlsxFile(rows, {
            fileName: `Reporte_de_Compras_por_Categorías_de_Insumos_${date}.xlsx`,
            columns: [
                {
                    width: 20
                },
                {
                    width: 20
                }
            ],
            fontSize: 10,
            fontFamily: 'Calibri'
        })
        setLoading(false)
    }

    return (
        <Button
            variant="alo-gray:md-xl"
            onClick={download}
            isLoading={isLoading}
            isDisabled={!controlList.getQuery('start_date')}
            rightIcon={<DownloadIcon fill="white" />}
        >
            Descargar
        </Button>
    )
}
