import { createContext, ReactNode, useState } from 'react'
import RunwayState from '../types/runway_state'

const RunwayContext = createContext<RunwayState>({
    selectedItems: sessionStorage.getItem('selected_items')
        ? JSON.parse(sessionStorage.getItem('selected_items') as string)
        : [],
    saveSelectedItems: () => null
})

export default RunwayContext

export function RunwayProvider({ children }: { children: ReactNode }) {
    const [selectedItems, setSelectedItems] = useState<
        RunwayState['selectedItems']
    >(() =>
        sessionStorage.getItem('selected_items')
            ? JSON.parse(sessionStorage.getItem('selected_items') as string)
            : []
    )

    function saveSelectedItems(data: RunwayState['selectedItems']) {
        sessionStorage.setItem('selected_items', JSON.stringify(data))
        setSelectedItems(data)
    }

    return (
        <RunwayContext.Provider value={{ selectedItems, saveSelectedItems }}>
            {children}
        </RunwayContext.Provider>
    )
}
