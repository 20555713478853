import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import { close, states } from '../../../lib/modalConfirm'
import Alert from '../../../components/Icon/Alert'

export default function ModalAreYouSure() {
    return (
        <>
            <Flex justifyContent="center" mb={6}>
                <Icon as={Alert} fill="error" fontSize="5xl" />
            </Flex>
            <Text mb={4} textAlign="center" fontSize="xl" fontWeight="bold">
                Revise los datos de la compra
            </Text>
            <Text mb={10} textAlign="center" fontSize="md">
                Luego de registrar esta Compra no se podrá editar ni eliminar.
                ¿Desea registrar la Compra?
            </Text>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
                gridGap={2}
            >
                <Button
                    variant="alo-gray:lg-md"
                    bg="transparent"
                    color="alo-gray"
                    w="40"
                    onClick={close}
                >
                    Cancelar
                </Button>
                <Button
                    variant="alo-green:lg-md"
                    w="40"
                    type="submit"
                    isLoading={states.isLoading}
                >
                    Sí, Registrar
                </Button>
            </Flex>
        </>
    )
}
