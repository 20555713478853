import { Box, Flex, Input } from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import Clean from '../../../../components/Clean'
import SelectWithLabel from '../../../../components/SelectWithLabel'
import Warehouse from '../../../../models/warehouse'
import { listKardex } from '../../storage'

export default function Actions({ warehouses }: { warehouses: Warehouse[] }) {
    const { clear, ...props } = listKardex.useSearch()
    const dateProps = listKardex.useFilter('date')

    function selectHandler(ev: ChangeEvent<HTMLSelectElement>) {
        const value = ev.target.value
        if (value === '') {
            listKardex.deleteQuery('warehouse_id')
        } else {
            listKardex.setQuery('warehouse_id', value)
        }
        listKardex.load()
    }

    return (
        <Flex
            mt={4}
            mb={4}
            w={{
                xl: 'auto',
                lg: 'full'
            }}
            justifyContent={{
                md: 'space-between',
                base: 'flex-end'
            }}
        >
            <Flex>
                <Clean value={props.value} onClick={clear}>
                    <Input
                        {...props}
                        placeholder="🏸 Buscar por nombre de producto"
                    />
                </Clean>
                <SelectWithLabel
                    ml={4}
                    label="Seleccionar almacén:"
                    placeholder="Todos"
                    onChange={selectHandler}
                    defaultValue={
                        (listKardex.getQuery('warehouse_id') as string) || ''
                    }
                >
                    {warehouses.map(warehouse => (
                        <option key={warehouse.id} value={warehouse.id}>
                            {warehouse.name}
                        </option>
                    ))}
                </SelectWithLabel>
            </Flex>
            <Box>
                <Input {...dateProps} type="date" />
            </Box>
        </Flex>
    )
}
