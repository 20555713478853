import { Box, Button, Heading, Text } from '@chakra-ui/react'
import Check from '../../../components/Icon/Check'
import colors from '../../../config/theme/colors'
import ModalErrorSend from '../../Statement/ModalContents/ModalErrorSend'
import useDetails from '../hooks/useDetails'
import BoxResult from './BoxResult'
import ListItems from './ListItems'

export default function Details() {
    const {
        openModal,
        dataModal,
        setOpenModal,
        subtotal,
        discount,
        total,
        to,
        alias,
        confirmPayment,
        accumulatedPayment,
        typeOrder,
        OpenSplitPayment,
        order_id,
        account_id
    } = useDetails()

    return (
        <>
            <Box
                bg="white"
                borderRadius="lg"
                py="8"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minH="calc(100vh - 180px)"
                boxShadow="md"
            >
                <Box>
                    <Heading size="md" textAlign="center" mb={1}>
                        {account_id ? 'NUEVA CUENTA' : 'NUEVO PEDIDO'}
                    </Heading>
                    <Text textAlign="center" fontSize="xs">
                        {alias.toUpperCase()}
                    </Text>
                    <Text
                        textAlign="center"
                        fontSize="sm"
                        fontWeight="bold"
                        mb={8}
                    >
                        {to}
                    </Text>
                    <ListItems />
                </Box>
                <Box px="8">
                    <BoxResult border>
                        <Text fontSize="16px">Subtotal</Text>
                        <Text fontSize="16px">S/ {subtotal.toFixed(2)}</Text>
                    </BoxResult>
                    <BoxResult>
                        <Text fontSize="16px">Descuento</Text>
                        <Text fontSize="16px">S/ {discount.toFixed(2)}</Text>
                    </BoxResult>
                    <BoxResult>
                        <Text fontSize="20px" pt="6">
                            TOTAL
                        </Text>
                        <Text fontSize="20px" pt="6">
                            S/ {total.toFixed(2)}
                        </Text>
                    </BoxResult>
                </Box>
            </Box>
            <Button
                bg={colors.success}
                color="white"
                leftIcon={<Check fill={colors.white} />}
                w="full"
                mt={{ base: 2, md: 6 }}
                size="lg"
                minH="48px"
                borderRadius="xl"
                isDisabled={
                    typeOrder === 'free' ? false : accumulatedPayment < total
                }
                onClick={confirmPayment}
                _hover={{}}
            >
                Confirmar Pago (S/ {total.toFixed(2)})
            </Button>
            {order_id && !account_id && (
                <Button
                    color={colors.success}
                    w="full"
                    mt="3"
                    size="lg"
                    minH="48px"
                    borderRadius="xl"
                    onClick={OpenSplitPayment}
                    _hover={{}}
                    borderColor={colors.success}
                    variant="outline"
                >
                    Pago avanzado
                </Button>
            )}
            <ModalErrorSend
                message={dataModal.message}
                receipts={dataModal.receipts}
                isOpen={openModal}
                setOpen={setOpenModal}
            />
        </>
    )
}
