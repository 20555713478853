import { listProvider } from '../storage'
import DeleteProvider from './DeleteProvider'
import Edition from './Edition'
import { Box, SimpleGrid } from '@chakra-ui/react'

export default function Row() {
    const data = listProvider.useData()

    return (
        <Box>
            {data.map((provider, i) => (
                <SimpleGrid
                    key={i}
                    columns={4}
                    textAlign="center"
                    className="row tr"
                >
                    <Box color="gray.600" py="4">
                        {provider.id}
                    </Box>
                    <Box color="gray.600" py="4">
                        {provider.name + ' ' + (provider.lastname ?? '').trim()}
                    </Box>
                    <Box color="gray.600" py="4">
                        {provider.document_number
                            ? provider.document_number
                            : '-'}
                    </Box>
                    <Box color="gray.600" py="4">
                        <Edition provider={provider} />
                        <DeleteProvider provider={provider} />
                    </Box>
                </SimpleGrid>
            ))}
        </Box>
    )
}
