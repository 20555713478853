import { render, useRender } from 'redity'
import { Keys } from '../constants'
import { storagePage } from '../storage'
import useSelectedItems from './useSelectedItems'

export default function useMainContent() {
    const r = useRender()
    function submitAddDiscountHandler(value: string, type: string) {
        storagePage.discount = Number(value)
        storagePage.typeDiscount = type
        r()
        render(Keys.DETAILS)
        render(Keys.RESULT_PAY)
    }

    function deleteDiscount() {
        storagePage.discount = Number(0)
        storagePage.typeDiscount = 'amount'
        r()
        render(Keys.DETAILS)
        render(Keys.RESULT_PAY)
    }

    const { subtotal, discount, total, typeDiscount, valuePercentDiscount } =
        useSelectedItems()

    return {
        subtotal,
        discount,
        total,
        valuePercentDiscount,
        typeDiscount,
        submitAddDiscountHandler,
        deleteDiscount
    }
}
