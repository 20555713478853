import { Icon } from '@chakra-ui/react'
import Trash from '../../../components/Icon/Trash'
import colors from '../../../config/theme/colors'
import Delete from '../../../lib/delete'
import Provider from '../../../models/provider'
import { listProvider } from '../storage'

export default function DeleteProvider({ provider }: { provider: Provider }) {
    return (
        <Delete
            title="Eliminar proveedor"
            path={`/api/providers/${provider.id}`}
            element={click => (
                <Icon
                    onClick={click}
                    fill={colors.error}
                    as={Trash}
                    cursor="pointer"
                />
            )}
            done={() => listProvider.load()}
        >
            {'¿Esta seguro de eliminar el proveedor '}
            <b>{provider.name + ' ' + (provider.lastname ?? '').trim()}</b>?
        </Delete>
    )
}
