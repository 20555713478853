import {
    FormControl,
    FormLabel,
    Input,
    InputProps,
    SelectProps,
    Select,
    Grid,
    Icon,
    useMediaQuery
} from '@chakra-ui/react'
import ErrorHelper from '../../../components/ErrorHelper'
import { formProvider } from '../storage'
import Search from '../../../components/Icon/Search'

export default function FormProvider() {
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)')

    return (
        <>
            <DocumentType />

            <Grid
                templateColumns={
                    isSmallScreen ? 'repeat(1, 2fr)' : 'repeat(2, 1fr)'
                }
                gap={6}
            >
                <Phone />
                <Email />
            </Grid>
            <Address />
        </>
    )
}

function DocumentType() {
    const { props, helper, error } =
        formProvider.useField<SelectProps>('document_type')
    return (
        <>
            <FormControl mb={4}>
                <FormLabel>
                    Tipo de documento <span className="required">*</span>
                </FormLabel>
                <Select {...props} placeholder="Seleccionar tipo de documento">
                    <option value={1}>DNI</option>
                    <option value={2}>RUC</option>
                    <option value={10}>Otros</option>
                </Select>
                <ErrorHelper helper={helper || error} />
            </FormControl>
            <DocumentNumber
                required={props.value && props.value != '10' ? true : false}
            />
            {props.value == '2' && <Name type="social" required={true} />}
            {['1', '10'].includes(String(props.value)) && (
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                    <Name type="name" required={true} />
                    <Lastname required={true} />
                </Grid>
            )}
        </>
    )
}

function Name({ type, required }: { type: string; required: boolean }) {
    const { props, helper, error } = formProvider.useField<InputProps>('name')
    return (
        <FormControl mb={4}>
            <FormLabel>
                {type == 'social' ? 'Razón social ' : 'Nombres '}
                {required && <span className="required">*</span>}
            </FormLabel>
            <Input
                {...props}
                placeholder={
                    type == 'social'
                        ? 'Ingresar el nombre del negocio'
                        : 'Ingresar los nombres'
                }
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Lastname({ required }: { required: boolean }) {
    const { props, helper, error } =
        formProvider.useField<InputProps>('lastname')
    return (
        <FormControl mb={4}>
            <FormLabel>
                {'Apellidos '}
                {required && <span className="required">*</span>}
            </FormLabel>
            <Input {...props} placeholder="Ingresar los apellidos" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function DocumentNumber({ required }: { required: boolean }) {
    const { props, helper, error } =
        formProvider.useField<InputProps>('document_number')
    return (
        <FormControl mb={4}>
            <FormLabel position="relative">
                {'N° de documento '}
                {required && <span className="required">*</span>}
                <Icon
                    as={Search}
                    fill="#434343"
                    fontSize="xl"
                    position="absolute"
                    right={1}
                    top={10}
                    zIndex={1}
                    pointerEvents="none"
                />
            </FormLabel>
            <Input
                {...props}
                paddingRight={'50px'}
                placeholder="Ingresar el número de documento"
                autoFocus={true}
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Phone() {
    const { props, helper, error } = formProvider.useField<InputProps>('phone')
    return (
        <FormControl mb={4}>
            <FormLabel>Teléfono</FormLabel>
            <Input {...props} placeholder="Ingresar el número" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Email() {
    const { props, helper, error } = formProvider.useField<InputProps>('email')
    return (
        <FormControl mb={4}>
            <FormLabel>Correo electrónico</FormLabel>
            <Input
                {...props}
                maxLength={180}
                placeholder="Ingresar el correo"
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function Address() {
    const { props, helper, error } =
        formProvider.useField<InputProps>('address')
    return (
        <FormControl mb={4}>
            <FormLabel>Dirección</FormLabel>
            <Input
                {...props}
                placeholder="Ingrear la dirección del proveedor"
            />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
