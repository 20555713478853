import { Box, Heading, Icon, Text } from '@chakra-ui/react'
import Close from '../../../../components/Icon/Close'
import colors from '../../../../config/theme/colors'
import useSession from '../../../../hooks/useSession'
import IceCream from '../../ContentModals/IceCream'
import useListItems from './useListItems'
import { storagePage } from '../../storage'
import filterItems from '../../utilities/filterItems'
import FeatureSettings from './FeatureSettings'
import ItemInfo from './ItemInfo'
import { getDataForEdition } from './utilities'
import { Fragment } from 'react'

export default function ListItems() {
    const {
        clickOpenModalHandler,
        clickDeleteItemHandler,
        saveItemWithFeatures
    } = useListItems()

    const { userCanCancelItems } = useSession()
    const { selectedItems, editOrderTable, hasOrderTable } = storagePage

    const isEditable = (hasOrderTable && editOrderTable) || !hasOrderTable
    const canDeleteItems = !hasOrderTable || userCanCancelItems()

    return (
        <Box maxHeight="calc(65vh)" overflow="auto" px="6">
            {filterItems(selectedItems).map((selected, i) => (
                <Box color="alo-gray" key={i}>
                    <Heading size="sm" my="3">
                        {selected.category_item_name}
                    </Heading>
                    {selected.items.map((item, _i) => (
                        <Fragment key={_i}>
                            {item.quantity !== 0 && (
                                <Box position="relative">
                                    <IceCream
                                        itemId={item.id}
                                        onDone={(featureSelected, quantity) =>
                                            saveItemWithFeatures(
                                                selected.category_item_id,
                                                item.id,
                                                featureSelected,
                                                quantity,
                                                item._id_generated as string
                                            )
                                        }
                                        {...(item.feature_settings && {
                                            edition: getDataForEdition(
                                                item.feature_settings
                                            )
                                        })}
                                        quantity={item.quantity}
                                        element={click => (
                                            <Box
                                                {...(isEditable
                                                    ? {
                                                          onClick: () => {
                                                              if (
                                                                  item.feature_settings &&
                                                                  item
                                                                      .feature_settings
                                                                      .length >
                                                                      0
                                                              ) {
                                                                  click()
                                                              } else {
                                                                  clickOpenModalHandler(
                                                                      item,
                                                                      selected.category_item_id
                                                                  )
                                                              }
                                                          }
                                                      }
                                                    : {})}
                                            >
                                                <ItemInfo
                                                    item={item}
                                                    isEditable={isEditable}
                                                />
                                                <FeatureSettings
                                                    featureSettings={
                                                        item.feature_settings
                                                    }
                                                />
                                            </Box>
                                        )}
                                    />
                                    {isEditable && canDeleteItems && (
                                        <Icon
                                            as={Close}
                                            fontSize={{
                                                xl: 18,
                                                lg: 15
                                            }}
                                            cursor="pointer"
                                            position="absolute"
                                            right="0"
                                            top="3"
                                            zIndex="1"
                                            fill={colors.secondary}
                                            ml={5}
                                            onClick={() =>
                                                clickDeleteItemHandler(
                                                    selected.category_item_id,
                                                    item.id,
                                                    _i
                                                )
                                            }
                                        />
                                    )}
                                </Box>
                            )}
                        </Fragment>
                    ))}
                </Box>
            ))}
            {filterItems(selectedItems).length === 0 && (
                <Text color="gray-3" fontSize="sm" textAlign="center" mt={2}>
                    Selecciona platos o bebidas
                </Text>
            )}
        </Box>
    )
}
