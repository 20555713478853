export default function AddIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11 10"
            {...props}
        >
            <path
                d="M10.1663 5.66683H6.16634V9.66683H4.83301V5.66683H0.833008V4.3335H4.83301V0.333496H6.16634V4.3335H10.1663V5.66683Z"
                fill="black"
            />
        </svg>
    )
}
