import { GridItem, Heading } from '@chakra-ui/react'
import colors from '../../../config/theme/colors'
import { GroupsProps } from './types'

export default function Groups({ title, children, span = 1 }: GroupsProps) {
    return (
        <GridItem
            borderRadius="md"
            bg="white"
            p="3"
            borderWidth="1px"
            gridColumn={'span ' + span}
        >
            <Heading
                size="sm"
                color={colors.white}
                py="1"
                px="3"
                borderRadius="md"
                mb={3}
                bg={colors['alo-blue']}
            >
                {title}
            </Heading>
            {children}
        </GridItem>
    )
}
