import { Box, SimpleGrid } from '@chakra-ui/react'
import { ReactNode } from 'react'
import './provider.css'
import Row from './Row'

export default function Provider() {
    return (
        <Box className="grid-table">
            <SimpleGrid
                columns={4}
                textAlign="center"
                color="gray.600"
                className="thead"
            >
                <Label>#</Label>
                <Label>Nombre</Label>
                <Label>Documento</Label>
                <Label>Acciones</Label>
            </SimpleGrid>
            <Row />
        </Box>
    )
}

function Label({ children }: { children: ReactNode }) {
    return (
        <Box
            fontSize="sm"
            fontWeight="bold"
            py="3"
            alignSelf={'center'}
            className="th"
        >
            {children}
        </Box>
    )
}
