import YapeLogo from '../../assets/yape-logo.png'
import PlinLogo from '../../assets/plin-logo.png'
import BcpLogo from '../../assets/bcp-logo.png'
import InterbankLogo from '../../assets/interbank-logo.png'
import BBVALogo from '../../assets/bbva-logo.png'
import ScotiabankLogo from '../../assets/scotiabank-logo.png'
import PedidosYaLogo from '../../assets/pedidosya-logo.png'

export const paymentLogos: any = {
    'plin-logo': PlinLogo,
    'bcp-logo': BcpLogo,
    'yape-logo': YapeLogo,
    'interbank-logo': InterbankLogo,
    'bbva-logo': BBVALogo,
    'scotiabank-logo': ScotiabankLogo,
    'pedidosya-logo': PedidosYaLogo
}
