import { Link, ListItem, UnorderedList } from '@chakra-ui/react'
import { Link as ReachLink } from 'react-router-dom'
import colors from '../../../config/theme/colors'
import { GroupItemProps } from './types'

export default function GroupItem({ to, label }: GroupItemProps) {
    return (
        <UnorderedList fontSize="sm" ml={5}>
            <ListItem color={colors['gray-2']}>
                <Link as={ReachLink} to={to}>
                    {label}
                </Link>
            </ListItem>
        </UnorderedList>
    )
}
