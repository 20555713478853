// config access route in src/config/access

import { Grid, useMediaQuery } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Groups from './Groups'
import GroupItem from './Groups/GroupItem'

export default function Reports() {
    const [isSmallScreen] = useMediaQuery('(max-width: 768px)')

    return (
        <Sidebar>
            <SimpleNav title="Reportes" />
            <Grid
                templateColumns={
                    isSmallScreen ? 'repeat(1, 1fr)' : 'repeat(5, 1fr)'
                }
                gap={6}
            >
                <Groups title="Ventas">
                    <GroupItem to="/reports/receipts" label="Comprobantes" />
                </Groups>
                <Groups title="Compras" span={2}>
                    <GroupItem
                        to="/reports/purchases"
                        label="Detalle de compras"
                    />
                    <GroupItem
                        to="/reports/purchase_categories"
                        label="Compras por categorías"
                    />
                </Groups>
            </Grid>
        </Sidebar>
    )
}
