import { Flex, Grid, Text } from '@chakra-ui/react'
import { useRender } from 'redity'
import { Keys } from '../constant'
import { storagePage } from '../storage'
import AnnulmentReport from './BoardContent/AnnulmentReport'
import Arching from './BoardContent/Arching'
import CashSummary from './BoardContent/CashSummary'
import CategoryReport from './BoardContent/CategoryReport'
import CourtesyDetail from './BoardContent/CourtesyDetail'
import Movements from './BoardContent/Movements'
import PaymentMethodReport from './BoardContent/PaymentMethodReport'
import ProofPaymentReport from './BoardContent/ProofPaymentReport'
import SaleDetail from './BoardContent/SaleDetail'
import Card from '../Components/Card'
import StatBox from '../Components/StatBox'
import CanceledSales from './BoardContent/CanceledSales'

export default function HistoryBoard() {
    useRender(Keys.OPENING_HISTORY_BOARD)

    const { cashboxMovements } = storagePage

    const realValue = cashboxMovements
        .map(i => i.realValue)
        .reduce((a, b) => a + b, 0)

    const startDates: string[] = []
    const endDates: string[] = []
    cashboxMovements.map(i => {
        startDates.push(i.startedAt)
        endDates.push(i.finishedAt)
    })

    const orderlyStartDates = startDates.sort()
    const orderlyEndDates = endDates.sort()

    let endDate
    if (orderlyEndDates[0] == '-') {
        endDate = '-'
    } else {
        endDate = orderlyEndDates[orderlyEndDates.length - 1]
    }

    return (
        <Flex gridGap={2} direction="column">
            <Flex gridGap={2} direction={{ base: 'column', md: 'row' }}>
                <Card direction="column" w={{ base: 'full', md: '50%' }}>
                    <Flex mb={1}>
                        <Text as="b" mr="2">
                            Fecha de inicio:
                        </Text>
                        <Text>{orderlyStartDates[0]}</Text>
                    </Flex>
                    <Flex>
                        <Text as="b" mr="2">
                            Fecha de fin:
                        </Text>
                        <Text>{endDate ? endDate : '-'}</Text>
                    </Flex>
                </Card>
                <Card w={{ base: 'full', md: '50%' }}>
                    <StatBox
                        label="Total de venta"
                        value={realValue}
                        withSymbol={true}
                    />
                </Card>
            </Flex>

            <Flex gridGap={2} direction={{ base: 'column', md: 'row' }}>
                <ProofPaymentReport type="note" />
                <ProofPaymentReport type="invoice" />
                <ProofPaymentReport type="ticket" />
            </Flex>

            <Grid
                templateColumns={{
                    base: '1fr',
                    md: 'repeat(3, minmax(30%, 100%))'
                }}
                gap={2}
            >
                <AnnulmentReport type="order" />
                <AnnulmentReport type="item" />
                <CanceledSales />
            </Grid>

            <PaymentMethodReport />

            <CategoryReport />

            <Flex gridGap={2} direction={{ base: 'column', md: 'row' }}>
                <SaleDetail />
                <CourtesyDetail />
            </Flex>

            <Flex gridGap={2} direction={{ base: 'column', md: 'row' }}>
                <Movements type="income" />
                <Movements type="expense" />
            </Flex>

            <Flex gridGap={2} direction={{ base: 'column', md: 'row' }}>
                <CashSummary />
                <Arching />
            </Flex>
        </Flex>
    )
}
