import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import { DataExpense } from '../../types'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import StatDivider from '../../Components/StatDivider'
import ViewDetailButton from '../../Components/ViewDetailButton'

export default function Movements({ type }: { type: 'income' | 'expense' }) {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    let title, quantity, total
    const data: DataExpense[] = []
    if (type == 'income') {
        title = 'Movimientos de ingreso'
        cashboxMovements.map(i => data.push(...i.dataIncomes))
        quantity = data.length
        total = cashboxMovements
            .map(i => i.totalIncomes)
            .reduce((a, b) => a + b, 0)
    } else {
        title = 'Movimientos de egreso'
        cashboxMovements.map(i => data.push(...i.dataExpenses))
        quantity = data.length
        total = cashboxMovements
            .map(i => i.totalExpenses)
            .reduce((a, b) => a + b, 0)
    }

    return (
        <Card
            direction="column"
            gridGap={4}
            w={{ base: 'full', md: '49.5%', lg: '50%' }}
        >
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                {title}
            </Text>
            <StatCard>
                <StatBox label={'Cant. Movimientos'} value={quantity} />
                <StatDivider />
                <StatBox label={'Total'} value={total} withSymbol={true} />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer>
                    <Table size="sm">
                        <Thead>
                            <Tr>
                                <Th>Concepto</Th>
                                <Th>Descripción</Th>
                                <Th>Monto</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((movement, i) => (
                                <Tr key={i}>
                                    <Td>{movement.conceptName}</Td>
                                    <Td>{movement.description}</Td>
                                    <Td isNumeric>
                                        {movement.value.toFixed(2)}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th colSpan={2}>Total</Th>
                                <Th isNumeric>S/{total.toFixed(2)}</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
