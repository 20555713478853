import { Button, Flex, Select } from '@chakra-ui/react'
import Sidebar from '../../components/Sidebar'
import SimpleNav from '../../components/SimpleNav'
import Tabs, { Tab } from '../../components/Tabs'
import Category from './Category'
import Feature from './Feature'
import FiltersFeature from './Feature/FiltersFeature'
import Letter from './Letter'
import FiltersLetter from './Letter/FiltersLetter'
import Supply from './Supply'
import SupplyCategory from './SupplyCategory'
import FiltersSupply from './Supply/FiltersSupply'
import FiltersSupplyCategory from './SupplyCategory/FiltersSupplyCategory'

import useControl from './useControl'
import useInitial from './useInitial'

export default function LetterManagement() {
    useInitial()
    const { tabProps, openModal, getTextButton } = useControl()

    return (
        <Sidebar>
            <SimpleNav title="Gestión de productos" />
            <Tabs {...tabProps}>
                <Tab value="item">Productos</Tab>
                <Tab value="category">Categorías</Tab>
                <Tab value="feature">Características</Tab>
                <Tab value="supply">Insumos</Tab>
                <Tab value="supply_category">Categorías de insumos</Tab>
            </Tabs>
            <Flex
                justifyContent="space-between"
                display={{ base: 'flex', md: 'none' }}
            >
                <Select
                    w="200px"
                    value={tabProps.value}
                    onChange={e => tabProps.onTab(e.target.value)}
                >
                    <option value="item">Productos</option>
                    <option value="category">Categorías</option>
                    <option value="supply">Insumos</option>
                    <option value="supply_category">
                        Categorías de insumos
                    </option>
                    <option value="feature">Características</option>
                </Select>
                <Button variant="alo-blue:md-xl" onClick={openModal}>
                    + Nuevo
                </Button>
            </Flex>
            <Flex
                mt={4}
                mb={4}
                w={{
                    xl: 'auto',
                    lg: tabProps.value === 'item' ? 'full' : 'auto'
                }}
                justifyContent={{
                    md:
                        tabProps.value === 'category'
                            ? 'flex-end'
                            : 'space-between',
                    base: 'flex-end'
                }}
            >
                {tabProps.value === 'item' && <FiltersLetter />}
                {tabProps.value === 'supply' && <FiltersSupply />}
                {tabProps.value === 'supply_category' && (
                    <FiltersSupplyCategory />
                )}
                {tabProps.value === 'feature' && <FiltersFeature />}
                <Button
                    variant="alo-blue:md-xl"
                    onClick={openModal}
                    display={{ base: 'none', md: 'inline' }}
                    ml="2"
                >
                    {getTextButton()}
                </Button>
            </Flex>
            {tabProps.value === 'item' && <Letter />}
            {tabProps.value === 'category' && <Category />}
            {tabProps.value === 'supply' && <Supply />}
            {tabProps.value === 'supply_category' && <SupplyCategory />}
            {tabProps.value === 'feature' && <Feature />}
        </Sidebar>
    )
}
