import createKeys from '../../utilities/createKeys'

export const Keys = createKeys('CONFIRM_ORDER', [
    'DETAILS',
    'TYPE_CONSUMPTION',
    'TYPE_ORDER',
    'TABLE_PAYMENTS',
    'RESULT_PAY',
    'PAYMENTS_METHODS'
])

export const TypeIdentificationDocument: Record<number, string> = {
    1: 'DNI',
    2: 'RUC',
    3: 'Carnet de extranjería',
    4: 'Pasaporte',
    5: 'Partida de nacimiento',
    6: 'Otros'
}
