import { Tr, Td } from '@chakra-ui/react'
import List from '../../../lib/list'
import { listSupplyCategory } from '../storage'
import DeleteSupplyCategory from './DeleteSupplyCategory'
import Edition from './Edition'

export default function SupplyCategory() {
    return (
        <List
            control={listSupplyCategory}
            tbody={(supply_category, e) => (
                <Tr key={e}>
                    <Td textAlign="center" width="28">
                        {e}
                    </Td>
                    <Td textAlign="center">{supply_category.name}</Td>
                    <Td textAlign="center">
                        <Edition supply_category={supply_category} />
                        <DeleteSupplyCategory
                            supply_category={supply_category}
                        />
                    </Td>
                </Tr>
            )}
        />
    )
}
