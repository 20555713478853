import { render, useRender } from 'redity'
import modal from '../../../../lib/modal'
import { SelectedItem } from '../../../../types/runway_state'
import Keys from '../../keys'
import Confirm from '../../ContentModals/Confirm'
import { FeatureSelected } from '../../ContentModals/IceCream/types'
import { formItem, storagePage } from '../../storage'
import getFeatureSettingSubFeatures from '../../utilities/getFeatureSettingSubFeatures'
import getItemSelected from '../../utilities/getItemSelected'
import useSubmitModalConfirm from '../../hooks/useSubmitModalConfirm'

export default function useListItems() {
    useRender(Keys.DETAILS_LIST_ITEMS)

    const { selectedItems } = storagePage

    const submitHandler = useSubmitModalConfirm()

    function clickOpenModalHandler(
        item: SelectedItem['items'][number],
        category_item_id: number
    ): void {
        storagePage.modalUtils.category_item_id = category_item_id
        storagePage.modalUtils.item_id = item.id
        formItem.store.set('category_item_id', category_item_id)
        formItem.store.set('name', item.name)
        formItem.store.set('quantity', item.quantity)
        formItem.store.set('currentQuantity', item.quantity_recorded)
        formItem.store.set('description', item.description)
        formItem.store.set('price', item.price.toString())
        formItem.store.set('isDisable_description', false)
        formItem.init(formItem.store)

        modal(Confirm, {
            size: 'lg',
            cleaned: true,
            onSubmit: submitHandler
        })
    }

    function clickDeleteItemHandler(
        category_item_id: number,
        item_id: number,
        index: number
    ) {
        const currentSelected = selectedItems.find(
            selected => selected.category_item_id === category_item_id
        ) as SelectedItem

        if (storagePage.hasOrderTable) {
            const currentItem = currentSelected.items.find(
                (item, i) => item.id === item_id && i === index
            ) as typeof storagePage['selectedItems'][number]['items'][number]
            currentItem.quantity = 0
        } else {
            if (currentSelected.items.length === 1) {
                storagePage.selectedItems = selectedItems.filter(
                    selected => selected.category_item_id !== category_item_id
                )
            } else {
                currentSelected.items = currentSelected.items.filter(
                    // (item, i) => item.id !== item_id
                    (_, i) => i !== index
                )
            }
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
    }

    function saveItemWithFeatures(
        categoryItemId: number,
        itemId: number,
        featureSelected: FeatureSelected[],
        quantity: number,
        idGenerated: string
    ) {
        const { item: currentItem } = getItemSelected(
            categoryItemId,
            itemId,
            idGenerated
        )

        if (currentItem) {
            currentItem.quantity = quantity
            currentItem.feature_settings = featureSelected.map(fs => ({
                feature_id: fs.feature_id,
                quantity: fs.quantity,
                name: fs.name,
                feature_setting_id: fs.feature_setting_id,
                feature_setting_sub_features: getFeatureSettingSubFeatures(
                    fs.subs
                )
            }))
        }

        render(Keys.DETAILS_LIST_ITEMS)
        render(Keys.CONTENT_BOTTOM)
        render(Keys.LIST_ITEM)
    }

    return {
        clickOpenModalHandler,
        clickDeleteItemHandler,
        saveItemWithFeatures
    }
}
