import { merge, parse } from 'fter'
import { useEffect, useState } from 'react'
import { get } from '../lib/http'
import { Result } from '../lib/http/type'

export function useGet<T>(path: string, defaultValue: T): ResultUseGet<T> {
    const [result, setResult] = useState<Result<T>>(() => ({
        data: {
            result: defaultValue || (null as any),
            pagination: null,
            status: 200,
            message: ''
        },
        error: null
    }))
    const [queries, setQueries] = useState<Record<string, any>>({})
    const [isLoading, setLoading] = useState(false)
    const [isInitialLoading, setInitialLoading] = useState(false)

    useEffect(() => {
        setInitialLoading(true)
        send(path).then(() => {
            setInitialLoading(false)
        })
    }, [])

    async function send(nextPath: string) {
        setLoading(true)
        const { data, error } = await get<T>(nextPath)
        if (error) {
            setResult(states => ({
                ...states,
                error: error
            }))
            if (error.type !== 'cancel') {
                setLoading(false)
            }
        } else {
            setResult({
                data,
                error: null
            })
            setLoading(false)
        }
    }

    async function reload() {
        await send(path)
    }

    async function setQuery(key: string, value: string | number | boolean) {
        const values = { ...queries, [key]: value }
        setQueries(values)
        await send(merge(path, parse(values)))
    }

    return {
        ...result,
        reload,
        isLoading,
        setQuery,
        queries,
        isInitialLoading
    }
}

interface ResultUseGet<T> extends Result<T> {
    reload: () => void
    isLoading: boolean
    isInitialLoading: boolean
    setQuery: (key: string, value: string | number | boolean) => void
    queries: Record<string, any>
}
