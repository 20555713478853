const TypeIdentificationDocument: Record<number, string> = {
    1: 'DNI',
    6: 'RUC',
    4: 'Carnet de extranjería',
    7: 'Pasaporte',
    8: 'Partida de nacimiento',
    0: 'Otros'
}

export const TypeIdentificationDocumentByCode: Record<string, string> = {
    '1': 'DNI',
    '6': 'RUC',
    '4': 'Carnet de extranjería',
    '7': 'Pasaporte',
    '8': 'Partida de nacimiento',
    '0': 'Otros'
}

export default TypeIdentificationDocument
