import { storagePage } from '../storage'

export default function getItemsForPut(userId: number | null) {
    const items = storagePage.selectedItems
        .map(item => item.items)
        .flat()
        .filter(
            item =>
                (item.quantity !== 0 && !item.order_item_id) ||
                item.order_item_id
        )
    const nextItems: DataForPut[] = []

    for (const item of items) {
        const restDataItem: DataForPut = {
            id: item.order_item_id, //autonumerico de la tabla order_items
            item_id: item.id,
            quantity: item.quantity,
            price: item.price,
            aditional_information: item.description,
            user_price_edition: userId //debe enviar el id del usuario que edita, cuando se edita el precio de un ítem
        }

        if (item.feature_settings && item.feature_settings.length > 0) {
            const nextItem = nextItems.find(_item => _item.item_id === item.id)

            const feature_setting_sub_features: DataFeatureSetting['feature_setting_sub_features'] =
                item.feature_settings
                    .map(fs =>
                        fs.feature_setting_sub_features.map(fssf => ({
                            sub_feature_id: fssf.sub_feature_id,
                            quantity: fssf.quantity,
                            id: fssf.feature_setting_sub_feature_id,
                            aditional_information: fssf.aditional_information
                        }))
                    )
                    .flat()

            if (nextItem) {
                nextItem.quantity = nextItem.quantity + item.quantity
                const featureSettings =
                    nextItem.feature_settings as DataFeatureSetting[]

                featureSettings.push({
                    quantity: item.quantity,
                    feature_setting_sub_features,
                    id: item.feature_settings[0].feature_setting_id
                })
            } else {
                nextItems.push({
                    ...restDataItem,
                    quantity: item.quantity,
                    feature_settings: [
                        {
                            quantity: item.quantity,
                            feature_setting_sub_features,
                            id: item.feature_settings[0].feature_setting_id
                        }
                    ]
                })
            }
        } else {
            nextItems.push(restDataItem)
        }
    }

    return nextItems
}

interface DataForPut {
    id?: number
    item_id: number
    quantity: number
    price: number
    aditional_information: string
    user_price_edition: number | null
    feature_settings?: DataFeatureSetting[]
}

interface DataFeatureSetting {
    quantity: number
    id?: number
    feature_setting_sub_features: Array<{
        id?: number
        sub_feature_id: number
        quantity: number
        aditional_information: string
    }>
}
