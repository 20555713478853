import { Flex, Input } from '@chakra-ui/react'
import Clean from '../../../components/Clean'
import { listPurchase } from '../storage'

export default function FiltersPurchase() {
    const { clear, ...props } = listPurchase.useSearch()

    return (
        <Flex mr="4" width="100%">
            <Clean value={props.value} onClick={clear} width="100%">
                <Input
                    w="100%"
                    placeholder="🏸 Buscar por Nº de comprobante o proveedor"
                    {...props}
                />
            </Clean>
        </Flex>
    )
}
