import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import Description from '../../../../components/Icon/Description'
import colors from '../../../../config/theme/colors'
import { SelectedItem } from '../../../../types/runway_state'
import getNumberWithTwoDecimals from '../../../../utilities/getNumberWithTwoDecimals'

export default function ItemInfo({
    item,
    isEditable
}: {
    item: SelectedItem['items'][number]
    isEditable: boolean
}) {
    let restCost = 0
    if (item.feature_settings) {
        restCost = item.feature_settings.reduce(
            (c, d) =>
                c +
                d.feature_setting_sub_features.reduce(
                    (e, f) => e + f.price * f.quantity,
                    0
                ),
            0
        )
    }
    const total = (item.price + restCost) * item.quantity

    return (
        <Flex
            justifyContent="space-between"
            align="center"
            role="group"
            fontSize="lg"
            py={2}
            {...(isEditable
                ? {
                      cursor: 'pointer',
                      _hover: {
                          bg: 'base-4'
                      }
                  }
                : {})}
            mx={-6}
            px={6}
        >
            <Text
                textAlign="left"
                fontSize={{
                    xl: '16px',
                    lg: '14px'
                }}
            >
                <Box mr={1} minW="6" as="span" display="inline-block">
                    {item.quantity}
                </Box>
                {item.name}
                {item.description && (
                    <Icon
                        as={Description}
                        fill={colors['gray-2']}
                        fontSize="20"
                        ml={2}
                    />
                )}
            </Text>
            <Text
                textAlign="right"
                mr="8"
                fontSize={{ xl: '16px', lg: '14px' }}
            >
                S/
                {getNumberWithTwoDecimals(total)}
            </Text>
        </Flex>
    )
}
