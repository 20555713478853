import { Flex } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'

export default function TypeField() {
    const [value, setValue] = useState('1')

    return (
        <Flex mb={4}>
            <Type value="1" onChecked={setValue} isChecked={value === '1'}>
                Boleta
            </Type>
            <Type value="2" onChecked={setValue} isChecked={value === '2'}>
                Factura
            </Type>
            <input value={value} type="hidden" name="type_voucher_id" />
        </Flex>
    )
}

function Type({ children, isChecked = false, value, onChecked }: TypeProps) {
    return (
        <Flex
            px="8"
            py="2"
            w="50%"
            justifyContent="center"
            cursor="pointer"
            fontSize="sm"
            border="1px solid"
            borderColor={isChecked ? 'gray-2' : 'gray.300'}
            color={isChecked ? 'white' : 'gray.700'}
            bg={isChecked ? 'gray-2' : 'white'}
            transition=".1s ease"
            onClick={() => onChecked(value)}
        >
            {children}
        </Flex>
    )
}

interface TypeProps {
    children: ReactNode
    isChecked?: boolean
    value: string
    onChecked: (value: string) => void
}
