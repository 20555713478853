import Grid from '../Icon/Grid'
import Check from '../Icon/Check'
import Printer from '../Icon/Printer'
import RoomService from '../Icon/RoomService'
import Social from '../Icon/Social'
import DashBoardIcon from '../Icon/DashBoardIcon'
import DraftLine from '../Icon/DraftLine'
import Description from '../Icon/Description'

const dataLinks = [
    {
        label: 'Dashboard',
        path: '/dashboard',
        Icon: DashBoardIcon
    },
    {
        label: 'Gestión de productos',
        path: '/cards',
        Icon: RoomService
    },
    {
        label: 'Gestion de usuarios',
        path: '/users',
        Icon: Social
    },
    {
        label: 'Gestión de Mesas',
        path: '/tables',
        Icon: Grid
    },
    {
        label: 'Gestión de almacén',
        path: '/warehouses',
        Icon: DraftLine
    },
    {
        label: 'Declaración SUNAT',
        path: '/statement',
        Icon: Check
    },
    {
        label: 'Gestión de Impresoras',
        path: '/printerMachines',
        Icon: Printer
    },
    {
        label: 'Gestión de compras',
        path: '/admin_purchases',
        Icon: Description
    },
    {
        label: 'Reportes',
        path: '/reports',
        Icon: Description
    }
]

export default dataLinks
