import {
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Tr
} from '@chakra-ui/react'
import { useState } from 'react'
import { storagePage } from '../../storage'
import Card from '../../Components/Card'
import StatBox from '../../Components/StatBox'
import StatCard from '../../Components/StatCard'
import ViewDetailButton from '../../Components/ViewDetailButton'

export default function CashSummary() {
    const [showDetail, setShowDetail] = useState(false)

    const { cashboxMovements } = storagePage

    const totalCash = cashboxMovements
        .map(i => i.summaryTotalCash)
        .reduce((a, b) => a + b, 0)

    const openingAmount = cashboxMovements
        .map(i => i.summaryOpeningAmount)
        .reduce((a, b) => a + b, 0)

    const saleCash = cashboxMovements
        .map(i => i.summarySalesCash)
        .reduce((a, b) => a + b, 0)

    const expenses = cashboxMovements
        .map(i => i.summaryExpensesMov)
        .reduce((a, b) => a + b, 0)

    const incomes = cashboxMovements
        .map(i => i.summaryIncomeMov)
        .reduce((a, b) => a + b, 0)

    return (
        <Card
            direction="column"
            gridGap={4}
            w={{ base: 'full', md: '49.5%', lg: '50%' }}
        >
            <Text as="b" mr="2" fontSize="xl" align="center" w="full">
                Resumen de efectivo
            </Text>
            <StatCard>
                <StatBox
                    label={'Total efectivo'}
                    value={totalCash}
                    withSymbol={true}
                />
            </StatCard>
            <ViewDetailButton
                showDetail={showDetail}
                setShowDetail={setShowDetail}
            />
            {showDetail && (
                <TableContainer>
                    <Table size="sm">
                        <Tbody>
                            <Tr>
                                <Td>Monto de apertura</Td>
                                <Td isNumeric>{openingAmount.toFixed(2)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Efectivo de venta</Td>
                                <Td isNumeric>{saleCash.toFixed(2)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Mov. ingresos</Td>
                                <Td isNumeric>{incomes.toFixed(2)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Mov. egresos</Td>
                                <Td isNumeric>{expenses.toFixed(2)}</Td>
                            </Tr>
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Total Efectivo</Th>
                                <Th isNumeric>S/{totalCash.toFixed(2)}</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Card>
    )
}
