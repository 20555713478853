import { useEffect } from 'react'
import { listProvider, listSupply, listPurchase } from './storage'

export default function useInitial() {
    useEffect(() => {
        Promise.all([
            listProvider.load(),
            listPurchase.load(),
            listSupply.load()
        ])
    }, [])
}
