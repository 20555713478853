import {
    FormControl,
    FormLabel,
    SelectProps,
    Select,
    Grid,
    Text,
    InputProps,
    Input,
    Box,
    Button,
    SimpleGrid,
    Icon
} from '@chakra-ui/react'
import { useGet } from '../../../hooks/useHttp'
import ErrorHelper from '../../../components/ErrorHelper'
import { formPurchase, listSupply } from '../storage'
import Provider from '../../../models/provider'
import { ReactNode, useState } from 'react'
import FiltersSupply from './FiltersSupply'
import SupplyRow from './SupplyRow'
import Warehouse from '../../../models/warehouse'
import Trash from '../../../components/Icon/Trash'
import Pagination from '../../../lib/list/Pagination'
import ContextSupplySearch from './ContextSupplySearch'

export default function FormPurchase() {
    const { value, setValue: setAddSupply } =
        formPurchase.useValue('add_supplies')

    const { data: warehouses } = useGet<Warehouse[]>(
        '/api/warehouses?sort=name',
        []
    )
    const { props: warehouseProps, helper: warehouseHelper } =
        formPurchase.useField<SelectProps>('warehouse_id')

    const onCloseHandler = () => {
        const footerModal = document.querySelector(
            '.chakra-modal__footer'
        ) as HTMLElement
        if (footerModal) footerModal.style.display = 'flex'

        const headerModal = document.querySelector(
            '.chakra-modal__header'
        ) as HTMLElement
        if (headerModal) headerModal.innerHTML = 'Nueva Compra'

        listSupply.setQuery('search', '')
        listSupply.setQuery('page', 1)
        listSupply.setQuery('supply_category_id', '')

        setAddSupply(false)
    }

    const {
        value: purchaseSupplies,
        setValue: setPurchaseSupplies,
        helper: errorPurchaseSupplies
    } = formPurchase.useValue('purchase_supplies')

    const handleOnChangeSupply = (
        index: number,
        valuei: string,
        field: string
    ) => {
        purchaseSupplies[index][field] = valuei
        setPurchaseSupplies([...purchaseSupplies])
    }

    const getTotal = () => {
        let total = 0
        for (const v of purchaseSupplies) {
            total += (v.quantity ?? 0) * (v.unit_cost ?? 0)
        }
        return total.toLocaleString('es-PE', {
            style: 'currency',
            currency: 'PEN'
        })
    }

    const onDeleteSupply = (index: number) => {
        purchaseSupplies.splice(index, 1)
        setPurchaseSupplies([...purchaseSupplies])
    }

    const getDetail = (purchaseSupply: any) => {
        if (purchaseSupply.supply_presentation) {
            return {
                name:
                    purchaseSupply.supply.name +
                    ' - ' +
                    purchaseSupply.supply_presentation.name,
                stock:
                    purchaseSupply.supply_presentation?.warehouse_supply
                        ?.stock ?? '-',
                type_measure: purchaseSupply.supply_presentation.type_measure
            }
        } else {
            return {
                name: purchaseSupply.supply.name,
                stock: purchaseSupply.supply?.warehouse_supply?.stock ?? '-',
                type_measure: purchaseSupply.supply.type_measure
            }
        }
    }

    const [supplyChecked, setSupplyChecked] = useState<any>([])

    const onAddHandler = () => {
        const newList: any = []
        for (const item of supplyChecked) {
            const newPurchaseSupply: any = {
                quantity: 0,
                unit_cost: 0,
                total: 0
            }
            if (item.type == 'supply') {
                newPurchaseSupply.supply_id = item.model.id
                newPurchaseSupply.supply = item.model
                newList.push(newPurchaseSupply)
            } else if (item.type == 'supply_presentation') {
                newPurchaseSupply.supply_presentation_id = item.model.id
                newPurchaseSupply.supply_presentation = item.model
                newPurchaseSupply.supply_id = item.model.supply.id
                newPurchaseSupply.supply = item.model.supply
                newList.push(newPurchaseSupply)
            }
        }
        setPurchaseSupplies([...purchaseSupplies, ...newList])
        onCloseHandler()
    }

    return (
        <>
            {!value && (
                <>
                    <Text mb={3} fontWeight="bold" fontSize="20px">
                        Información General
                    </Text>
                    <ProviderSelect />
                    <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={4}>
                        <VoucherType />
                        <VoucherNumber />
                    </Grid>
                    <Text mb={3} fontWeight="bold" fontSize="20px">
                        Detalle de compra
                    </Text>

                    <Box
                        bg="white"
                        borderRadius="md"
                        borderWidth="1px"
                        borderColor="#E2E8F0"
                        py="26px"
                        px="32px"
                        mb={4}
                        position="relative"
                    >
                        <FormControl mb={4}>
                            <FormLabel>
                                Almacén <span className="required">*</span>
                            </FormLabel>
                            <Select
                                {...warehouseProps}
                                placeholder="Seleccionar almacén"
                            >
                                {warehouses.result.map(warehouse => (
                                    <option
                                        key={warehouse.id}
                                        value={warehouse.id}
                                    >
                                        {warehouse.name}
                                    </option>
                                ))}
                            </Select>
                            <ErrorHelper helper={warehouseHelper} />
                        </FormControl>
                        <ListPurchaseSupplies
                            callback={setAddSupply}
                            warehouse_id={warehouseProps.value.toString()}
                        />
                        {errorPurchaseSupplies && (
                            <FormControl mb={4}>
                                <ErrorHelper helper={errorPurchaseSupplies} />
                            </FormControl>
                        )}
                        <Box className="grid-table list-responsive" mt={4}>
                            <SimpleGrid
                                columns={6}
                                textAlign="center"
                                color="gray.600"
                                className="thead"
                            >
                                <Label align="left">Producto</Label>
                                <Label>Stock actual</Label>
                                <Label>Unidad</Label>
                                <Label>Cantidad</Label>
                                <Label>Costo unitario</Label>
                                <Label>Sub total</Label>
                            </SimpleGrid>

                            <Box
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: '450px'
                                }}
                            >
                                {Array.isArray(purchaseSupplies) &&
                                    purchaseSupplies.map(
                                        (purchaseSupply: any, ps: number) => (
                                            <SimpleGrid
                                                key={ps}
                                                columns={6}
                                                textAlign="center"
                                                className="row tr"
                                            >
                                                <Box
                                                    color="gray.600"
                                                    py="4"
                                                    textAlign="left"
                                                    pl="4"
                                                >
                                                    {
                                                        getDetail(
                                                            purchaseSupply
                                                        ).name
                                                    }
                                                </Box>
                                                <Box color="gray.600" py="4">
                                                    {
                                                        getDetail(
                                                            purchaseSupply
                                                        ).stock
                                                    }
                                                </Box>
                                                <Box color="gray.600" py="4">
                                                    {getDetail(purchaseSupply)
                                                        .type_measure?.name ??
                                                        '-'}
                                                </Box>
                                                <Box
                                                    color="gray.600"
                                                    py="4"
                                                    px="10px"
                                                >
                                                    <Input
                                                        bg={'white'}
                                                        type="number"
                                                        borderRadius={0}
                                                        textAlign="center"
                                                        value={
                                                            purchaseSupply.quantity ??
                                                            0
                                                        }
                                                        onChange={e =>
                                                            handleOnChangeSupply(
                                                                ps,
                                                                e.target.value,
                                                                'quantity'
                                                            )
                                                        }
                                                        placeholder="0"
                                                    />
                                                </Box>
                                                <Box
                                                    color="gray.600"
                                                    py="4"
                                                    px="10px"
                                                    display="flex"
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                >
                                                    <Box
                                                        bg="white"
                                                        paddingY="7.5px"
                                                        paddingX="12px"
                                                        border="1px solid lightgrey"
                                                    >
                                                        S/
                                                    </Box>
                                                    <Input
                                                        bg={'white'}
                                                        type="number"
                                                        borderRadius={0}
                                                        textAlign="right"
                                                        minWidth="100px"
                                                        value={
                                                            purchaseSupply.unit_cost ??
                                                            0
                                                        }
                                                        onChange={e =>
                                                            handleOnChangeSupply(
                                                                ps,
                                                                e.target.value,
                                                                'unit_cost'
                                                            )
                                                        }
                                                        placeholder="0"
                                                    />
                                                </Box>
                                                <Box
                                                    color="gray.600"
                                                    py="4"
                                                    pr="10px"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="flex-end"
                                                    gridColumnGap="8px"
                                                    whiteSpace="nowrap"
                                                >
                                                    {(
                                                        (purchaseSupply.quantity ??
                                                            0) *
                                                        (purchaseSupply.unit_cost ??
                                                            0)
                                                    ).toLocaleString('es-PE', {
                                                        style: 'currency',
                                                        currency: 'PEN'
                                                    })}
                                                    <Icon
                                                        onClick={() => {
                                                            onDeleteSupply(ps)
                                                        }}
                                                        fill="#FE6A5E"
                                                        as={Trash}
                                                        cursor="pointer"
                                                    />
                                                </Box>
                                            </SimpleGrid>
                                        )
                                    )}
                                {!Array.isArray(purchaseSupplies) ||
                                    (purchaseSupplies.length == 0 && (
                                        <SimpleGrid
                                            columns={1}
                                            textAlign="center"
                                            className="row tr"
                                        >
                                            <Box bg="white" py="4">
                                                Sin productos
                                            </Box>
                                        </SimpleGrid>
                                    ))}
                            </Box>
                        </Box>
                        <Box
                            textAlign="right"
                            className="row tr"
                            bg="#F6F6F6 !important"
                            marginTop="32px"
                        >
                            <Box py="4" display="inline-block">
                                Total de compra
                            </Box>
                            <Box
                                display="inline-block"
                                py="4"
                                pr="10px"
                                pl="10px"
                                whiteSpace="nowrap"
                                fontWeight={600}
                                textAlign="right"
                            >
                                {getTotal()}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {value && (
                <>
                    <FiltersSupply />
                    <Box className="grid-table list-responsive" mt={4}>
                        <SimpleGrid
                            columns={5}
                            gridTemplateColumns="[col1-start] 60px [col2-start] repeat(4, 1fr)"
                            textAlign="center"
                            color="gray.600"
                            className="thead"
                        >
                            <Label className="fixed-width">#</Label>
                            <Label>Producto</Label>
                            <Label>Unidad</Label>
                            <Label>Almacén</Label>
                            <Label>Stock actual</Label>
                        </SimpleGrid>
                        <ContextSupplySearch.Provider
                            value={{ supplyChecked, setSupplyChecked }}
                        >
                            <SupplyRow
                                purchaseSupplies={purchaseSupplies}
                                warehouse={warehouses.result.find(
                                    (item: any) =>
                                        +item.id == +warehouseProps.value
                                )}
                            />
                        </ContextSupplySearch.Provider>
                    </Box>
                    <Pagination control={listSupply} />
                    <Box
                        display="flex"
                        marginTop="24px"
                        gridColumnGap="10px"
                        justifyContent="flex-end"
                    >
                        <Button
                            borderRadius="6px"
                            color="#1A202C"
                            border="1px solid #E2E8F0"
                            fontWeight="semibold"
                            bg="transparent"
                            fontSize="lg"
                            paddingY="11px"
                            paddingX="24px"
                            _hover={{}}
                            onClick={onCloseHandler}
                        >
                            Cancelar
                        </Button>
                        <Button
                            borderRadius="6px"
                            color="white"
                            fontWeight="semibold"
                            bg="#44C281"
                            fontSize="lg"
                            paddingY="11px"
                            paddingX="24px"
                            _hover={{}}
                            onClick={onAddHandler}
                        >
                            Agregar
                        </Button>
                    </Box>
                </>
            )}
        </>
    )
}

function Label({
    children,
    align = 'center',
    className = ''
}: {
    children: ReactNode
    align?: string
    className?: string
}) {
    return (
        <Box
            fontSize="sm"
            fontWeight="bold"
            py="3"
            alignSelf={align}
            className={'th ' + className}
        >
            {children}
        </Box>
    )
}

function ListPurchaseSupplies({
    warehouse_id,
    callback
}: {
    warehouse_id?: string
    callback: any
}) {
    const updateModal = () => {
        listSupply.load()
        callback(true)
        const footerModal = document.querySelector(
            '.chakra-modal__footer'
        ) as HTMLElement
        if (footerModal) footerModal.style.display = 'none'

        const headerModal = document.querySelector(
            '.chakra-modal__header'
        ) as HTMLElement
        if (headerModal) headerModal.innerHTML = 'Agregar productos a la compra'
    }
    return (
        <FormControl mb={4} className="purchaseSuppliesContainer">
            <FormLabel>
                Productos de compra <span className="required">*</span>
            </FormLabel>
            <Button
                isDisabled={!warehouse_id ? true : false}
                variant="alo-blue:md-xl"
                className="btnSearchSupply"
                onClick={updateModal}
            >
                Buscar
            </Button>
        </FormControl>
    )
}

function ProviderSelect() {
    const { data } = useGet<Provider[]>('/api/providers?sort=name', [])
    const { props, helper } = formPurchase.useField<SelectProps>('provider_id')

    return (
        <FormControl mb={4}>
            <FormLabel>
                Proveedor <span className="required">*</span>
            </FormLabel>
            <Select {...props} placeholder="Seleccionar proveedor">
                {data.result.map(provider => (
                    <option key={provider.id} value={provider.id}>
                        {(provider.name ?? '') +
                            ' ' +
                            (provider.lastname ?? '').trim()}
                    </option>
                ))}
            </Select>
            <ErrorHelper helper={helper} />
        </FormControl>
    )
}

function VoucherType() {
    const { props, helper, error } =
        formPurchase.useField<SelectProps>('voucher_type')
    return (
        <FormControl mb={4}>
            <FormLabel>Tipo de comprobante</FormLabel>
            <Select {...props} placeholder="Seleccionar tipo de comprobante">
                <option value={1}>Boleta</option>
                <option value={2}>Factura</option>
            </Select>
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}

function VoucherNumber() {
    const { props, helper, error } =
        formPurchase.useField<InputProps>('voucher_number')
    return (
        <FormControl mb={4}>
            <FormLabel>N° de comprobante</FormLabel>
            <Input {...props} placeholder="Ingresar Nº de comprobante" />
            <ErrorHelper helper={helper || error} />
        </FormControl>
    )
}
