import { Button, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { parse } from 'fter'
import { useState } from 'react'
import { useRender } from 'redity'
import writeXlsxFile, { Row, SheetData } from 'write-excel-file'
import { get } from '../../../lib/http'
import { ReportPurchaseDetail } from '../../../models/report_purchase_detail'
import { controlList } from '../storage'
import DownloadIcon from '../../../components/Icon/Download'

export default function Download() {
    useRender('Download')
    const toast = useToast()
    const [isLoading, setLoading] = useState(false)

    async function download() {
        setLoading(true)
        const start_date = controlList.getQuery('start_date') as string
        const end_date = controlList.getQuery('end_date') as string
        const sort = controlList.getQuery('sort')
        const order = controlList.getQuery('order')

        const path = parse(
            {
                start_date,
                end_date,
                sort,
                order
            },
            '/api/reports/purchase_details'
        )

        const { data, error } = await get<ReportPurchaseDetail[]>(path)

        if (error) {
            toast({
                status: 'error',
                title: error.message,
                position: 'top'
            })
            setLoading(false)
            return
        }

        const head: (...values: string[]) => Row[number][] = (...values) =>
            values.map<Row[number]>(val => ({
                value: val,
                fontWeight: 'bold',
                wrap: true,
                alignVertical: 'center',
                align: 'center'
            }))

        const rows: SheetData = [
            [],
            [
                {},
                {},
                {
                    value: 'REPORTE DE DETALLE DE COMPRAS',
                    fontWeight: 'bold',
                    span: 5,
                    height: 24,
                    alignVertical: 'center',
                    align: 'center',
                    fontSize: 15
                }
            ],
            [],
            [
                {
                    value: 'Fecha',
                    fontWeight: 'bold'
                },
                {
                    value: `${dayjs(start_date, 'YYYY-MM-DD').format(
                        'DD/MM/YYYY'
                    )} - ${dayjs(end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
                    span: 3
                }
            ],
            [],
            [],
            head(
                '# Compra',
                'Fecha',
                'Proveedor',
                'Categoría',
                'Insumo',
                'Unidad',
                'Cantidad',
                'Costo unitario',
                'Total'
            )
        ]

        const generateCode = (id: number) => {
            const minLength = 5
            const numberStr = String(id)
            const zeros = minLength - numberStr.length
            return `C${'0'.repeat(zeros)}${numberStr}`
        }

        let total = 0
        data.result.forEach(purchase_detail => {
            total += purchase_detail.total
            rows.push([
                {
                    value: generateCode(purchase_detail.id),
                    type: String
                },
                {
                    value: dayjs(purchase_detail.date).format('DD/MM/YYYY'),
                    type: String
                },
                {
                    value: purchase_detail.provider_name,
                    type: String,
                    align: 'center'
                },
                {
                    value: purchase_detail.category_name,
                    type: String,
                    align: 'center'
                },
                {
                    value: purchase_detail.name,
                    type: String
                },
                {
                    value: purchase_detail.type_measure_name,
                    type: String,
                    align: 'center'
                },
                {
                    value: Number(purchase_detail.quantity),
                    type: Number,
                    align: 'center'
                },
                {
                    value: purchase_detail.unit_cost,
                    type: Number,
                    format: '[$S/]#,##0.00'
                },
                {
                    value: purchase_detail.total,
                    type: Number,
                    format: '[$S/]#,##0.00'
                }
            ])
        })

        rows.push([
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
                value: total,
                type: Number,
                fontWeight: 'bold',
                format: '[$S/]#,##0.00'
            }
        ])

        const date = dayjs().format('YYYY_MM_DD_HH_mm_ss')

        await writeXlsxFile(rows, {
            fileName: `Reporte_de_Detalle_de_Compras_${date}.xlsx`,
            columns: [
                {
                    width: 12
                },
                {
                    width: 12
                },
                {
                    width: 20
                },
                {
                    width: 20
                },
                {
                    width: 50
                },
                {
                    width: 20
                },
                {
                    width: 15
                },
                {
                    width: 15
                },
                {
                    width: 15
                }
            ],
            fontSize: 10,
            fontFamily: 'Calibri'
        })
        setLoading(false)
    }

    return (
        <Button
            variant="alo-gray:md-xl"
            onClick={download}
            isLoading={isLoading}
            isDisabled={!controlList.getQuery('start_date')}
            rightIcon={<DownloadIcon fill="white" />}
        >
            Descargar
        </Button>
    )
}
